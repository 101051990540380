import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FilterEnum } from '../../models/filter-enum';
import { detailTabbedViewEnum, detailView,DetailedViewState } from '../../models/detail-view-enum';
@Injectable({
  providedIn: 'root'
})

export class CommunicationService {
  private closeRightNavSubject = new BehaviorSubject<boolean>(false);
  private filterToggleSubject = new BehaviorSubject<FilterEnum>(FilterEnum.none);
  private detailedViewToggleSubject = new BehaviorSubject<DetailedViewState>({
    detailView: detailView.none,
    detailTabbedViewEnum: detailTabbedViewEnum.none
  });
  private detailViewParameter = new BehaviorSubject<string>('');

  closeRightNav$ = this.closeRightNavSubject.asObservable();
  filterToggle$ = this.filterToggleSubject.asObservable();
  detailedViewToggle$ = this.detailedViewToggleSubject.asObservable();
  detailViewParam = this.detailViewParameter.asObservable();
  

  toggleFilter(filterName: FilterEnum) {
    this.filterToggleSubject.next(filterName);
  }

  toggleDetailView(detailName: detailView, detailTab?: detailTabbedViewEnum): void {
    this.detailedViewToggleSubject.next({
      detailView: detailName,
      detailTabbedViewEnum: detailTab ?? detailTabbedViewEnum.none
    });
  }

  closeRightNav() {
    this.closeRightNavSubject.next(true);
  }

  getDetailViewParameter(param: string) {
    this.detailViewParameter.next(param);
  }

  constructor() { }
}
