import { Component, Input, Output, EventEmitter, ChangeDetectorRef, inject } from '@angular/core';
import { detailTabbedViewEnum, detailView } from '../models/detail-view-enum';
import { CommunicationService } from '../service/communication/communication.service';
import { Alert } from '../models/alerts/alerts';
import { AlertService } from '../service/alerts/alert.service';

@Component({
  selector: 'app-alert-card',
  templateUrl: './alert-card.component.html',
  styleUrls: ['./alert-card.component.css']
})
export class AlertCardComponent {
  @Input() alert!: Alert;
  @Output() remove = new EventEmitter<void>();
  private cd = inject(ChangeDetectorRef);
constructor (private communicationService: CommunicationService,private alertService: AlertService) {
  
}

  onRemove(): void {
    console.log('Removing alert:', this.alert.id);
    this.markAsRead(this.alert.id);
    this.remove.emit();
    this.cd.detectChanges();
  }

  viewDetails(alert: Alert): void {
    console.log('Viewing details for:' + this.alert.reference);
    this.communicationService.toggleDetailView(this.alert.detailsView, this.alert.detailsTab);
    this.communicationService.getDetailViewParameter(this.alert.reference);
    this.markAsRead(this.alert.id);
    this.cd.detectChanges();
  }

  markAsRead(id: number) {
    this.alertService.updateAlertStatus(id).subscribe(_ => {
    })
  }
}


