import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './guard/auth.guard';
import { AlertComponent } from './alert/alert.component';
import { BookingManagementComponent } from './booking-management/booking-management.component';
import { DocumentManagementComponent } from './document-management/document-management.component';
import { SchedulesComponent } from './schedules/schedules.component';
import { ShipmentsComponent } from './shipments/shipments.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { DocumentListComponent } from './document-list/document-list.component';
import { AddBookingComponent } from './booking/add-booking/add-booking.component';

const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'dashboard', component: DashboardComponent, title: 'Dashboard' },
  { path: 'alerts', component: AlertComponent, title: 'Alerts' },
  { path: 'booking management', component: BookingManagementComponent, title: 'Booking Management' },
  { path: 'document management', component: DocumentListComponent, title: 'Documents' },
  { path: 'schedules', component: SchedulesComponent, title: 'Schedules' },
  { path: 'shipments', component: ShipmentsComponent, title: 'Shipments' },
  { path: 'analytics', component: AnalyticsComponent, title: 'Analytics' },
  { path: 'add-booking', component: AddBookingComponent, title: 'Add Booking'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
