<div class="container">
  <div class="map-container-wrapper">
    <div id="map" class="map-container">
      <div class="map-legend">
        <img class="legend-image" src="/assets/Dashed Line.svg" alt="Dashed line">
        <div class="legend-text">Expected route</div>
      </div>
    </div>
  </div>
</div>


