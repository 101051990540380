import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, inject, Input } from '@angular/core';
import { SchedulesService } from '../service/schedules/schedules.service';
import { IScheduleListModel } from '../models/schedules/schedule-list-model';
import { CommunicationService } from '../service/communication/communication.service';
import { FilterData } from '../models/list-model';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { ColumnSetup } from '../models/column-setup';
import { LookupService } from '../service/lookup/lookup.service';
import { LookupModel } from '../models/lookup-model';
import { Observable, Subject } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { ApiUserService } from '../service/user/api-user.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DataFilter } from '../models/filter-models';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-schedules-filter',
  templateUrl: './schedules-filter.component.html',
  styleUrls: ['./schedules-filter.component.css', '../filter-sidenav/filter-sidenav.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
 
export class SchedulesFilterComponent implements OnInit {


  private apiUserService = inject(ApiUserService);
  private destroy$ = new Subject<void>();
  isCustUser: boolean = false;

  private _snackBar = inject(MatSnackBar);


  loadPorts = new FormControl<LookupModel[]>([]);
  loadPortsMultiFilter = new FormControl<string>('');
  filteredLoadPorts!: Observable<LookupModel[]>;
  scheduleDataLoadPort: LookupModel[] = [];

  dischargePorts = new FormControl<LookupModel[]>([]);
  dischargePortsMultiFilter = new FormControl<string>('');
  filteredDischargePorts!: Observable<LookupModel[]>;
  scheduleDataDischargePort: LookupModel[] = [];

  countries = new FormControl<LookupModel[]>([]);
  countriesMultiFilter = new FormControl<string>('');
  filteredCountries!: Observable<LookupModel[]>;
  scheduleDataCountries: LookupModel[] = [];

  markets = new FormControl<LookupModel[]>([]);
  marketsMultiFilter = new FormControl<string>('');
  filteredMarkets!: Observable<LookupModel[]>;
  scheduleDataMarkets: LookupModel[] = [];

  latestacks = new FormControl();

  savedFilterName = new FormControl('');

  filterData: FilterData = {};
  filterName: string = '';
  datafilter: DataFilter = new DataFilter();
  savedFilters: DataFilter[] = [];

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  checkedItems: Set<string> = new Set<string>();
  scheduleDatalatestack: string[] = ['True', 'False'];

  constructor(private domSanitizer: DomSanitizer, private schedulesService: SchedulesService, private lookupService: LookupService, private cdr: ChangeDetectorRef, private dialog: MatDialog, private communicationService: CommunicationService,private matIconRegistry: MatIconRegistry) {
    this.matIconRegistry.addSvgIcon('backspace', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/backspace.svg'));
   }

  ngOnInit(): void {
    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isCustUser = this.apiUserService.IsCustUser;
          }
      });

    this.isCustUser = this.apiUserService.IsCustUser;
    this.loadScheduleData(); 
  }

  loadScheduleData(): void {

    this.schedulesService.sendActiveFilterToFilter$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.datafilter = data;
          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Filter data', error)
      });

    this.lookupService.getLoadPorts(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.scheduleDataLoadPort = data;
          this.loadPorts.setValue(this.scheduleDataLoadPort.filter(port => port.isSelected));
          this.filteredLoadPorts = this.loadPortsMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterLoadPorts(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Load Port data', error)
      });

    this.lookupService.getDischargePorts(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.scheduleDataDischargePort = data;
          this.dischargePorts.setValue(this.scheduleDataDischargePort.filter(port => port.isSelected));
          this.filteredDischargePorts = this.dischargePortsMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterDischargePorts(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Discharge Port data', error)
      });

    this.lookupService.getCountries(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.scheduleDataCountries = data;
          this.countries.setValue(this.scheduleDataCountries.filter(country => country.isSelected));
          this.filteredCountries = this.countriesMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterCountries(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Country data', error)
      });

    this.lookupService.getMarkets(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.scheduleDataMarkets = data;
          this.markets.setValue(this.scheduleDataMarkets.filter(data => data.isSelected));
          this.filteredMarkets = this.marketsMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterMarkets(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Country data', error)
      });


    if (this.datafilter.id !== 0) {
      this.filterName = this.datafilter.filterName;
      this.savedFilterName.setValue(this.filterName);
    }
  }


  processFilterData(key: string, value: string) {
    const dataArray = value
      .split(',')
      .filter(item => item.trim() !== '');

    this.filterData[key] = dataArray;

    console.log(this.filterData);
  }

  clearLookupFilter(control: FormControl): void {
    control.reset();
    control.setValue([]);
    console.log('Filter cleared:', control);
  }

  save(): void {
    if (!this.savedFilterName.value) {
      // Optionally, you can show an error message here
      console.error('Filter name cannot be empty');
      return;
    }

   this.createFilter();
    this.filterName = this.savedFilterName.value.toString();
    this.schedulesService.saveScheduleFilter(this.filterName, this.filterData).subscribe(_ => {
      this.schedulesService.filterNameSavedSend(this.filterName);
      this.schedulesService.getFilters().subscribe(result => {
        this.savedFilters = result;
        const savedFil = this.savedFilters.find(f => f.filterName === this.filterName);
        this.datafilter.id = savedFil!.id;
        this.datafilter.filterName = savedFil!.filterName;
        this.datafilter.filter = savedFil!.filter;
      });
    });
    this.openSnackBar('Your filter ' + this.filterName + ' has been created');
  }

  clearAllFilters(): void {
    this.clearLookupFilter(this.loadPorts);
    this.clearLookupFilter(this.dischargePorts);
    this.clearLookupFilter(this.markets);
    this.clearLookupFilter(this.countries);
    this.applyFilters();
    this.openSnackBar('Your filters have been cleared');
  }

  clearFilter(control: FormControl) {
    control.reset();
    control.setValue([]);
  }

  private filterLoadPorts(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.scheduleDataLoadPort;
    }
    return this.scheduleDataLoadPort.filter(loadPort =>
      loadPort.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterDischargePorts(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.scheduleDataDischargePort;
    }
    return this.scheduleDataDischargePort.filter(dischargePort =>
      dischargePort.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterCountries(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.scheduleDataCountries;
    }
    return this.scheduleDataCountries.filter(country =>
      country.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterMarkets(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.scheduleDataMarkets;
    }
    return this.scheduleDataMarkets.filter(market =>
      market.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  get isSavedFilterNameEmpty(): boolean {
    return !this.savedFilterName.value;
  }

  deleteFilter(): void {
    let filterName = this.datafilter.filterName;

    this.schedulesService.deleteScheduleFilter(this.datafilter.id).subscribe(result => {
      this.schedulesService.filterNameSavedSend('');
      this.savedFilterName.setValue('');
      this.datafilter.id = 0;
      this.datafilter.filterName = '';
      this.openSnackBar('Your filter, ' + filterName + ' has been deleted');
      console.log(result);
    });

    // this.lookupService.getDischargePorts().subscribe(data => {
    //   this.podList = data;
    // })

  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }
  applyFilters(): void {
    this.createFilter();
    this.updateCurrentFilter(0, '', this.filterData);
    this.schedulesService.transferFilterData(this.filterData);
    this.schedulesService.sendingActiveFilterToList(this.datafilter);
    this.closeRightDrawer();
    this.openSnackBar('Your filter has been applied');
    
  }

  createFilter(): void {
    if (this.loadPorts.value != null) {
      const loadPortCodes = this.parseFilterSelections(this.loadPorts.value);
      this.processFilterData('loadPortCode', loadPortCodes);
    }

    if (this.dischargePorts.value != null) {
      const dischargePortCodes = this.parseFilterSelections(this.dischargePorts.value);
      this.processFilterData('dischargePortCode', dischargePortCodes);
    }

    if (this.countries.value != null) {
      const countryCodes = this.parseFilterSelections(this.countries.value);
      this.processFilterData('countryCode', countryCodes);
    }

    if (this.markets.value != null) {
      const marketCodes = this.parseFilterSelections(this.markets.value);
      this.processFilterData('marketCode', marketCodes);
    }
  }

  parseFilterSelections(selections: LookupModel[]): string {
    const codes: string[] = selections.map(selection => selection.code);
    return codes.join(',');

  }

  updateCurrentFilter(id: number, name: string, filter: FilterData) {
    this.datafilter.id = id;
    this.datafilter.filterName = name;
    this.datafilter.filter = filter;
  }

  // processFilterData(key: string, value: string) {
  //   const dataArray = value
  //     .split(',')
  //     .filter(item => item.trim() !== '');

  //   this.filterData[key] = dataArray;
  // }

  // showInformation(result: any, message: string | null): void {
  //   const dialogRef = this.dialog.open(InformationComponent, {
  //     width: '300px',
  //     height: '250px',
  //     data: { resultStatus: result, showMessage: message },
  //   });
  // }

  // showError(message: string): void {
  //   console.error(message);
  //   this.showInformation('Document rejection Failed', message)
  // }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  //TODO: Fine a way to get this window to close without forcing it closed using CDR
  //Application : When a user clicks on apply filter this filter side nav needs to close
  //Scale: P3 None Critical
  closeRightDrawer(){
    this.communicationService.closeRightNav();
  }
}