<div class="container">
  <div class="title-wrapper">
    <div class="saved-view-wrap">
      <h1>Document Management</h1>
      <div class="saved-views">
        <div *ngFor="let savedFilter of savedFilters">
          <button mat-button class="Saved-filter-btn" [ngClass]="{'selected': currentFilter.id === savedFilter.id}" (click)="applyFilter(savedFilter.id)">
            {{ savedFilter.filterName }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="parameters-filter-wrap">
    <div class="legend-wrapper">
      <button mat-button class="alert-btns" [ngClass]="{'selected': selectedButton === 'approved'}"
              (click)="selectButton('approved')">
        <img src="/assets/Confirmed.svg" alt="" class="icon">
        Approved
      </button>
      <button mat-button class="alert-btns" [ngClass]="{'selected': selectedButton === 'awaitingapproval'}"
              (click)="selectButton('awaitingapproval')">
        <img src="/assets/Awaiting.svg" alt="" class="icon">
        Awaiting approval
      </button>
      <button mat-button class="alert-btns" [ngClass]="{'selected': selectedButton === 'rejected'}"
              (click)="selectButton('rejected')">
        <img src="/assets/Cancelled.svg" alt="" class="icon">
        Rejected
      </button>
    </div>
    <button mat-button class="filter-btn" (click)="onOpenFilter(FilterEnum.documentFilter)">
      <div class="filter-icon-wrap">
        <div class="filter-icon"></div>
        Show Filters
      </div>
    </button>
  </div>

  <div class="mat-elevation-z2" *ngIf="isMinWidth(769)">
    <table mat-table [dataSource]="dataSource" class="full-width-table" matSort aria-label="Elements">
      <ng-container *ngFor="let column of displayedColumns">
        <ng-container [matColumnDef]="column.name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!column.visible">
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row" [hidden]="!column.visible"
              [ngStyle]="column.name === 'documentStatusColor' ? {'background-color': getBackgroundColor(row['documentApprovalStatus'])} : {}">

            <ng-container *ngIf="column.name !== 'actions'">
              <ng-container *ngIf="column.type === 'date'">
                {{ row[column.name] | date: 'yyyy-MM-dd' }}
              </ng-container>
              <ng-container *ngIf="column.name === 'documentStatusColor'">
              </ng-container>
              <ng-container *ngIf="column.type !== 'date'&& column.name !== 'documentStatusColor'">
                {{ row[column.name] }}
              </ng-container>
            </ng-container>
            <ng-container *ngIf="column.name === 'indicators'">
              <button [ngClass]="row.hasSubscriptions ? 'indicator-btn-subbed' : 'indicator-btn'"  (click)="subscribeToAlert(row)">
                <mat-icon svgIcon="Subscribe"></mat-icon>
              </button>
              <button [ngClass]="row.hasAlerts ? 'indicator-btn-alerts-unread' : 'indicator-btn'" (click)="viewDetails(row, detailView.shipmentDetail, detailTabbedViewEnum.alertTab)">
                <mat-icon svgIcon="ViewAlerts"></mat-icon>
              </button>
            </ng-container>

            <!--Add a button for the 'actions' column-->
            <ng-container *ngIf="column.name === 'actions'">
              <button class="plannedRouteButton" color="primary" (click)="viewDetails(row, detailView.shipmentDetail, detailTabbedViewEnum.documentTab)">
                View Details<mat-icon>arrow_forward</mat-icon>
              </button>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="extractedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: extractedColumns;"></tr>
    </table>

    <mat-paginator #paginator [length]="dataSource.data.length" [pageIndex]="0" [pageSize]="20"
                   [pageSizeOptions]="[5, 10, 20, 50]" aria-label="Select page">
    </mat-paginator>
  </div>
  <!-- Cards for smaller screens -->
  <div *ngIf="!isMinWidth(768)">
    <div *ngFor="let row of dataSource.filteredData" class="card" id="mobileCard"
         [ngStyle]="{'border-left-color': getBackgroundColor(row.documentApprovalStatus)}">
      <ng-container *ngFor="let column of displayedColumns">
        <ng-container *ngIf="column.visible">
          <div *ngIf="column.name !== 'actions'&& column.name !== 'documentStatusColor'">
            <strong>{{ column.header }} |</strong> {{getValueForRowColumn(row, column.name)}}
          </div>
        </ng-container>
      </ng-container>
      <button class="plannedRouteButton" color="primary" (click)="viewDetails(row, detailView.shipmentDetail, detailTabbedViewEnum.documentTab)">
        Details <mat-icon>arrow_right_alt</mat-icon>
      </button>
    </div>
  </div>
</div>
