<div class="container">
  <div class="title-wrapper">
    <div class="saved-view-wrap">
      <h1>Analytics</h1>
      <div class="saved-views">
        <button mat-button class="Saved-filter-btn">saved view</button>
        <button mat-button class="Saved-filter-btn">saved view</button>
        <button mat-button class="Saved-filter-btn">saved view</button>
      </div>
    </div>

  </div>

  <div class="row">
    <div class="col-md-3">
      <mat-form-field>
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="dateFromPicker" placeholder="Choose a date">
        <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
        <mat-datepicker #dateFromPicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field>
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="dateToPicker" placeholder="Choose a date"/>
        <mat-datepicker-toggle matIconSuffix [for]="dateToPicker"></mat-datepicker-toggle>
        <mat-datepicker #dateToPicker>
        </mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-md-2">
      <button class="runReportButton" >Search<mat-icon>arrow_forward</mat-icon></button>
    </div>

    <div class="col-md-4 filter-btn-wrapper" style=" justify-content: end;">
      <button mat-button class="filter-btn">
        <div class="filter-icon-wrap">
          <div class="filter-icon"></div>
          Show Filters
        </div>
      </button>
    </div>
  </div>


  <div class="mat-elevation-z2">
    <table mat-table class="full-width-table" matSort aria-label="Elements">
      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
        <td mat-cell *matCellDef="let row">{{row.id}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row">{{row.name}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator #paginator
                   [length]="dataSource.data.length"
                   [pageIndex]="0"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 20]"
                   aria-label="Select page">
    </mat-paginator>
  </div>
</div>
