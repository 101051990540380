import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, model, OnInit, signal } from '@angular/core';
import { ApiUserService } from '../../service/user/api-user.service';
import { Subject } from 'rxjs/internal/Subject';
import { LookupModel } from '../../models/lookup-model';
import { FormControl } from '@angular/forms';
import { map, Observable, of, skip, startWith, takeUntil } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { BookingService } from '../../service/booking/booking.service';
import { LookupService } from '../../service/lookup/lookup.service';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { FilterData } from '../../models/list-model';
import { DataFilter } from '../../models/filter-models';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { AddBookingEnum, AddBookingModel, BookingDataTransferModel } from '../../models/booking-data-model';
import { ScheduleListModel } from '../../models/schedules/schedule-list-model';
import { BookingEditModel } from '../../models/bookings/booking-edit-model';
import { BookingSubmitResponse } from '../../models/bookings/booking-response-model';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-booking-input',
  templateUrl: './add-booking-input.component.html',
  styleUrl: './add-booking-input.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddBookingInputComponent implements OnInit {
  private apiUserService = inject(ApiUserService)
  private destroy$ = new Subject<void>();
  isCustUser: boolean = false;
  hideSingleSelectionIndicator = signal(false);
  hideMultipleSelectionIndicator = signal(false);

  @Input() bookingData!: BookingEditModel;

  weight = new FormControl(0);
  clientRefNo = new FormControl('');
  quantity = new FormControl(0);

 
  isChecked = false;
  
  isSterilized = new FormControl(false);
  isTemptale = new FormControl(false);

  loadDateTime = new FormControl();
  loadTime = new FormControl();
  temptaleDescription = new FormControl('');
  temptaleQTY = new FormControl(0);
  comment = new FormControl('');
  stepUp = new FormControl('');
  preVerificationKey = new FormControl('');
  prelimConsignee = new FormControl('');

  customers = new FormControl<string>('');
  customersMultiFilter = new FormControl<string>('');
  filteredCustomers!: Observable<LookupModel[]>;
  customersData: LookupModel[] = [];

  loadPorts = new FormControl<string>('')
  loadPortsMultiFilter = new FormControl<string>('');
  filteredLoadPorts!: Observable<LookupModel[]>;
  bookingDataLoadPort: LookupModel[] = [];

  dischargePorts = new FormControl<string>('')
  dischargePortsMultiFilter = new FormControl<string>('');
  filteredDischargePorts!: Observable<LookupModel[]>;
  bookingDataDischargePorts: LookupModel[] = [];

  finalDestinations = new FormControl<string>('')
  finalDestinationsMultiFilter = new FormControl<string>('');
  filteredFinalDestinations!: Observable<LookupModel[]>;
  bookingDataFinalDestinations: LookupModel[] = [];

  commodities = new FormControl<string>('');
  commoditiesMultiFilter = new FormControl<string>('');
  filteredCommodities!: Observable<LookupModel[]>;
  bookingDataCommodities: LookupModel[] = [];

  countries = new FormControl<string>('')
  countriesMultiFilter = new FormControl<string>('');
  filteredCountries!: Observable<LookupModel[]>;
  bookingDataCountries: LookupModel[] = [];

  containerTypes = new FormControl<string>('')
  containerTypesMultiFilter = new FormControl<string>('');
  filteredContainerTypes!: Observable<LookupModel[]>;
  bookingDataContainerTypes: LookupModel[] = [];

  vents = new FormControl<string>('')
  ventsMultiFilter = new FormControl<string>('');
  filteredVents!: Observable<LookupModel[]>;
  bookingDataVents: LookupModel[] = [];

  solasMethods = new FormControl<string>('')
  solasMethodsMultiFilter = new FormControl<string>('');
  filteredSolasMethods!: Observable<LookupModel[]>;
  bookingDataSolasMethods: LookupModel[] = [];

  loadpoint1 = new FormControl<string>('')
  loadpoint2 = new FormControl<string>('')
  loadpointsMultiFilter = new FormControl<string>('');
  filteredLoadpoints!: Observable<LookupModel[]>;
  bookingDataLoadpoints: LookupModel[] = [];  

  temperatures = new FormControl<string>('')
  temperaturesMultiFilter = new FormControl<string>('');
  filteredTemperatures!: Observable<LookupModel[]>;
  bookingDataTemperatures: LookupModel[] = [];

  filterData: FilterData = {};
  datafilter: DataFilter = new DataFilter();

  booking: AddBookingModel = new AddBookingModel();
  voyage: ScheduleListModel | null = null;

  loadHours: string = '';
  loadMinutes: string = '';

  constructor(private router: Router, private domSanitizer: DomSanitizer, private bookingDataService: BookingService, private lookupService: LookupService, private cdr: ChangeDetectorRef, private matIconRegistry: MatIconRegistry) {
    this.matIconRegistry.addSvgIcon('backspace', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/backspace.svg'));
  }

  private _snackBar = inject(MatSnackBar);
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  private handleError<T>(message: string, result?: T): Observable<T> {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-fail']
    });
    return of(result as T);
  }

  private openSnackBar(message: string, success: boolean = true) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: success ? ['snackbar-success'] : ['snackbar-fail']
    });
  }

  ngOnInit(): void {
    this.loadLookupData();
    this.loadBookingEditModel();
    this.subscribeToInputs()
  }

  loadBookingEditModel(): void {
    let confirmedPackDateString = this.bookingData.confirmedPackDate?.toString();
    let confirmedPackDate = new Date(confirmedPackDateString as string);
    if (confirmedPackDate !== null) {
      this.loadHours = confirmedPackDate.getHours().toString().padStart(2, '0');;
      this.loadMinutes = confirmedPackDate.getMinutes().toString().padStart(2, '0');;
    }
    else {
      this.loadHours = '00';
      this.loadMinutes = '00';
    }  

    this.clientRefNo.setValue(this.bookingData.clientReference);
    this.comment.setValue(this.bookingData.comments);
    this.commodities.setValue(this.bookingData.commodityCode);
    this.loadDateTime.setValue(this.bookingData.confirmedPackDate);
    this.loadTime.setValue(this.loadHours + ':' + this.loadMinutes);
    this.containerTypes.setValue(this.bookingData.containerTypeCode);
    this.customers.setValue(this.bookingData.customerCode);
    this.dischargePorts.setValue(this.bookingData.dischargePortCode);
    this.finalDestinations.setValue(this.bookingData.dischargePortCode);
    this.loadpoint1.setValue(this.bookingData.loadingPoint1);
    this.loadpoint2.setValue(this.bookingData.loadingPoint2);
    this.prelimConsignee.setValue(this.bookingData.receiverCode);
    this.preVerificationKey.setValue(this.bookingData.preVerificationKey);
    this.quantity.setValue(this.bookingData.quantity);
    this.solasMethods.setValue(this.bookingData.solasMethod);
    this.stepUp.setValue(this.bookingData.stepUp);
    this.isSterilized.setValue(this.bookingData.sterilized);
    this.temperatures.setValue(this.bookingData.temperatureCode);
    this.isTemptale.setValue(this.bookingData.temptale);
    this.temptaleDescription.setValue(this.bookingData.temptaleDescription);
    this.temptaleQTY.setValue(this.bookingData.temptaleQty);
    this.vents.setValue(this.bookingData.ventilationCode);
    this.weight.setValue(this.bookingData.weight);
  }

  subscribeToInputs(): void {

    this.clientRefNo.valueChanges.subscribe(newValue => { this.bookingData.clientReference = newValue as string; });
    this.comment.valueChanges.subscribe(newValue => { this.bookingData.comments = newValue as string; });
    this.commodities.valueChanges.subscribe(newValue => { this.bookingData.commodityCode = newValue as string; });
    this.loadDateTime.valueChanges.subscribe(newValue => { this.bookingData.confirmedPackDate = newValue as Date; });
    this.containerTypes.valueChanges.subscribe(newValue => { this.bookingData.containerTypeCode = newValue as string; });
    this.customers.valueChanges.subscribe(newValue => { this.bookingData.customerCode = newValue as string; });
    this.dischargePorts.valueChanges.subscribe(newValue => { this.bookingData.dischargePortCode = newValue as string; });
    this.finalDestinations.valueChanges.subscribe(newValue => { this.bookingData.clientReference = newValue as string; });
    this.loadpoint1.valueChanges.subscribe(newValue => { this.bookingData.loadingPoint1 = newValue as string; });
    this.loadpoint2.valueChanges.subscribe(newValue => { this.bookingData.loadingPoint2 = newValue as string; });
    this.loadDateTime.valueChanges.subscribe(newValue => { this.bookingData.confirmedPackDate = this.createDateTime(newValue as Date, this.loadTime.value) as Date; });
    this.loadTime.valueChanges.subscribe(newValue => { this.bookingData.confirmedPackDate = this.createDateTime(this.loadDateTime.value, newValue as string ) as Date; });
    this.prelimConsignee.valueChanges.subscribe(newValue => { this.bookingData.receiverCode = newValue as string; });
    this.preVerificationKey.valueChanges.subscribe(newValue => { this.bookingData.preVerificationKey = newValue as string; });
    this.quantity.valueChanges.subscribe(newValue => { this.bookingData.quantity = newValue as number; });
    this.solasMethods.valueChanges.subscribe(newValue => { this.bookingData.solasMethod = newValue as string; });
    this.stepUp.valueChanges.subscribe(newValue => { this.bookingData.stepUp = newValue as string; });
    this.isSterilized.valueChanges.subscribe(newValue => { this.bookingData.sterilized = newValue as boolean; });
    this.temperatures.valueChanges.subscribe(newValue => { this.bookingData.temperatureCode = newValue as string; });
    this.isTemptale.valueChanges.subscribe(newValue => { this.bookingData.temptale = newValue as boolean; });
    this.temptaleDescription.valueChanges.subscribe(newValue => { this.bookingData.temptaleDescription = newValue as string; });
    this.temptaleQTY.valueChanges.subscribe(newValue => { this.bookingData.temptaleQty = newValue as number; });
    this.vents.valueChanges.subscribe(newValue => { this.bookingData.ventilationCode = newValue as string; });
    this.weight.valueChanges.subscribe(newValue => { this.bookingData.weight = newValue as number; });

  }

  loadLookupData(): void {

    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isCustUser = this.apiUserService.IsCustUser;
          }
      });

    this.isCustUser = this.apiUserService.IsCustUser;

    this.lookupService.getCustomers(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.customersData = data;
          this.filteredCustomers = this.customersMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterCustomers(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Customers data', error)
      });

    this.lookupService.getLoadPorts(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.bookingDataLoadPort = data;
          this.filteredLoadPorts = this.loadPortsMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterLoadPorts(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Load Port data', error)
      });

    this.lookupService.getDischargePorts(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.bookingDataDischargePorts = data;
          this.filteredDischargePorts = this.dischargePortsMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterDischargePorts(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Discharge Port data', error)
      });

    this.lookupService.getDischargePorts(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.bookingDataFinalDestinations = data;
          this.filteredFinalDestinations = this.finalDestinationsMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterFinalDestinations(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Discharge Port data', error)
      });

    this.lookupService.getCommodities(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.bookingDataCommodities = data;
          this.filteredCommodities = this.commoditiesMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterCommodities(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Commodity data', error)
      });

    this.lookupService.getCountries(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.bookingDataCountries = data;
          this.filteredCountries = this.countriesMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterCountries(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Country data', error)
      });


    this.lookupService.getTemperatures(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.bookingDataTemperatures = data;
          this.filteredTemperatures = this.temperaturesMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterTemperatures(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Temperature data', error)
      });

    this.lookupService.getContainerTypes(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.bookingDataContainerTypes = data;
          this.filteredContainerTypes = this.containerTypesMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterContainerTypes(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Container data', error)
      });

      this.lookupService.getSolasMethods(this.datafilter.filter)
        .pipe(
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (data) => {
            this.bookingDataSolasMethods = data;
            this.filteredSolasMethods = this.solasMethodsMultiFilter.valueChanges.pipe(
              startWith(''),
              map(searchText => this.filterSolasMethods(searchText ?? ''))
            );

            this.cdr.markForCheck();
          },
          error: (error) => console.error('Error fetching Solas Method data', error)
        });

    this.lookupService.getLoadpoints(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.bookingDataLoadpoints = data;
          this.filteredLoadpoints = this.loadpointsMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterLoadpoints(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Load Port data', error)
      });

    this.lookupService.getVents(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.bookingDataVents = data;
          this.filteredVents = this.ventsMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterVents(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Load Port data', error)
      });
          
  }

  onCheckboxChange(event: MatCheckboxChange) {
    this.isChecked = event.checked; // Update the state based on the checkbox change
  }

  clearFilter(control: FormControl) {
    control.reset();
    control.setValue([]);
  }

  private filterLoadPorts(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataLoadPort;
    }
    return this.bookingDataLoadPort.filter(loadPort =>
      loadPort.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterDischargePorts(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataDischargePorts;
    }
    return this.bookingDataDischargePorts.filter(dischargePort =>
      dischargePort.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterFinalDestinations(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataFinalDestinations;
    }
    return this.bookingDataFinalDestinations.filter(finalDestination =>
      finalDestination.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterCommodities(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataCommodities;
    }
    return this.bookingDataCommodities.filter(commodity =>
      commodity.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterCountries(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataCountries;
    }
    return this.bookingDataCountries.filter(country =>
      country.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterTemperatures(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataTemperatures;
    }
    return this.bookingDataTemperatures.filter(country =>
      country.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterSolasMethods(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataSolasMethods;
    }
    return this.bookingDataSolasMethods.filter(solasMethod =>
      solasMethod.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterLoadpoints(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataLoadpoints;
    }
    return this.bookingDataLoadpoints.filter(loadpoint =>
      loadpoint.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterContainerTypes(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataContainerTypes;
    }
    return this.bookingDataContainerTypes.filter(containerType =>
      containerType.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterVents(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataVents;
    }
    return this.bookingDataVents.filter(vent =>
      vent.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterCustomers(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.customersData;
    }
    return this.customersData.filter(customer =>
      customer.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  public sterilizedClick(): void {
    if (this.isSterilized.value === true) {
      this.isSterilized.setValue(false)
    }
    else {
      this.isSterilized.setValue(true)
    }
  }

  public temptaleClick(): void {
    if (this.isTemptale.value === true) {
      this.isTemptale.setValue(false)
    }
    else {
      this.isTemptale.setValue(true)
    }
  }

  public sendToReefers(): void {
    this.bookingDataService.submitBooking(this.bookingData)
      .subscribe(data => {
        if (data.success) {
          // TODO: notify user success
          this.openSnackBar(`${data.appBookingCode}: ${data.status}`)
          this.router.navigate(['/booking management']);

          //alert(`${data.appBookingCode}: ${data.status}`);
        }
        else {
          // TODO: notify user failure
          this.handleError(`${data.appBookingCode}: ${data.status}`)

          //alert(`${data.appBookingCode}: ${data.status}`);
        }
      });
  }

  createDateTime(date: Date, time: string): Date {
    let hours: number, minutes: number;

    if (time.includes(':')) {
      [hours, minutes] = time.split(':').map(Number);
    } else {
      // Handle case where time string does not include ':'
      hours = parseInt(time, 10);
      minutes = 0;
    }

    // Create a new Date object to avoid mutating the original date
    const newDate = new Date(date);
    newDate.setHours(hours, minutes, 0, 0); // Set hours, minutes, seconds, and milliseconds

    // Return the combined date-time as a Date object
    return newDate;
  }


  formatTime() {
    const input = this.loadTime.value;
    const formattedTime = this.formatToISOTime(input);
    this.loadTime.setValue(formattedTime, { emitEvent: false });
  }

  formatToISOTime(input: string): string {
    // Validate the input time
    const control = new FormControl(input);
    const validationResult = this.timeValidator(control);

    if (validationResult) {
      return 'Invalid time'; // Return a string indicating invalid time
    }

    let hours: number, minutes: number;
    if (input.includes(':')) {
      [hours, minutes] = input.split(':').map(Number);
    } else {
      hours = Number(input.slice(0, 2));
      minutes = Number(input.slice(2, 4));
    }

    // Ensure hours and minutes are zero-padded
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  }

  timeValidator(control: FormControl) {
    const value = control.value;
    const timePattern = /^([01]\d|2[0-3])(:?)([0-5]\d)$/;
    return timePattern.test(value) ? null : 'Invalid time';
  }
}

function distinctUntilChanged(): import("rxjs").OperatorFunction<import("../../models/schedules/schedule-list-model").ScheduleListModel | null, unknown> {
    throw new Error('Function not implemented.');
}
