<div class="container col-md-10">
  <div class="title-wrapper">
    <div class="saved-view-wrap">
      <h1>{{editBookingTitle}}</h1>
    </div>
  </div>
  
  

  <div class="row">
    <div class="col-md-3">
      <mat-form-field>
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="dateFromPicker" placeholder="Choose a date" [formControl]="dateFrom">
        <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
        <mat-datepicker #dateFromPicker></mat-datepicker>

        <mat-error *ngIf="dateFrom.hasError('required')">Date is required.</mat-error>
        <mat-error *ngIf="dateFrom.hasError('dateInPast')">Date cannot be in the past.</mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field>
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="dateToPicker" placeholder="Choose a date" [formControl]="dateTo" />
        <mat-datepicker-toggle matIconSuffix [for]="dateToPicker"></mat-datepicker-toggle>
        <mat-datepicker #dateToPicker>
        </mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md-2">
      <mat-form-field *ngIf="loadPorts" style="width:30%">
        <mat-label>POL</mat-label>
        <mat-select [formControl]="loadPorts">
          <mat-option>
            <ngx-mat-select-search [formControl]="loadPortsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Load Port Not found"></ngx-mat-select-search>
          </mat-option>

          <mat-option *ngFor="let loadPort of filteredLoadPorts | async" [value]="loadPort.code">
            {{loadPort.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2">
      <mat-form-field *ngIf="dischargePorts" style="width:30%">
        <mat-label>POD</mat-label>
        <mat-select [formControl]="dischargePorts">
          <mat-option>
            <ngx-mat-select-search [formControl]="dischargePortsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Discharge Port Not found"></ngx-mat-select-search>
          </mat-option>

          <mat-option *ngFor="let dischargePort of filteredDischargePorts | async" [value]="dischargePort.code">
            {{dischargePort.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-2">
      <mat-form-field *ngIf="markets" style="width:30%">
        <mat-label>Market</mat-label>
        <mat-select [formControl]="markets">
          <mat-option>
            <ngx-mat-select-search [formControl]="marketsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Market Not found"></ngx-mat-select-search>
          </mat-option>

          <mat-option *ngFor="let market of filteredMarkets | async" [value]="market.code">
            {{market.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-2">
      <mat-form-field *ngIf="countries" style="width:30%">
        <mat-label>Country</mat-label>
        <mat-select [formControl]="countries">
          <mat-option>
            <ngx-mat-select-search [formControl]="countriesMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Country Not found"></ngx-mat-select-search>
          </mat-option>

          <mat-option *ngFor="let country of filteredCountries | async" [value]="country.code">
            {{country.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <ng-container *ngIf="!scheduleSelected">
      <div class="col-md-2">
        <button class="runReportButton" (click)="runFilter()">Search<mat-icon>arrow_forward</mat-icon></button>
      </div>
    </ng-container>
  </div>


  <ng-container *ngIf="bookingData$ | async as bookingData">
    <app-booking-voyage-search (bookingDataOutput)="subscribeToBookingVoyage($event)" [bookingData]="bookingData"></app-booking-voyage-search>
  </ng-container>

  <ng-container *ngIf="scheduleSelected">
    <ng-container *ngIf="bookingData$ | async as bookingData">
      <app-add-booking-input [bookingData]="bookingData"></app-add-booking-input>
    </ng-container>
  </ng-container>
</div>
