<div class="tracking-map-wrapper">
  <div class="tracking-map-container">
    <ng-container [ngSwitch]="mode">
      <!-- Road Tracking Component -->
      <!-- <app-road-tracking *ngSwitchCase="TransportMode.Road"
                         [vehicleLocation]="vehicleLocation"
                         [startLocation]="startLocation"
                         [endLocation]="endLocation">
      </app-road-tracking> -->

      <!-- Ocean Tracking Component -->
      <app-ocean-tracking *ngSwitchCase="TransportMode.Ocean"
                          [vehicleLocation]="vehicleLocation"
                          [startLocation]="startLocation"
                          [endLocation]="endLocation"
                          [voyageCoordinates]="voyageCoordinates">
      </app-ocean-tracking>

      <!-- Air Tracking Component -->
      <app-air-tracking *ngSwitchCase="TransportMode.Air"
                        [vehicleLocation]="vehicleLocation"
                        [startLocation]="startLocation"
                        [endLocation]="endLocation">
      </app-air-tracking>

      <!-- Fallback if mode is not recognized -->
      <div *ngSwitchDefault>
        <p>Invalid Transport Mode</p>
      </div>
    </ng-container>
  </div>
</div>
