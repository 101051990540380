import { Component, ViewChild, ChangeDetectorRef, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SchedulesService } from '../../service/schedules/schedules.service';
import { IScheduleListModel, ScheduleListModel } from '../../models/schedules/schedule-list-model';
import { FormControl } from '@angular/forms';
import { CommunicationService } from '../../service/communication/communication.service';
import { FilterData } from '../../models/list-model';
import { FilterEnum } from '../../models/filter-enum';
import { ColumnSetup } from '../../models/column-setup';
import { detailView } from '../../models/detail-view-enum';
import { of, Subject } from 'rxjs';
import { DataFilter, FilterTransferModel } from '../../models/filter-models';
import { takeUntil, catchError, distinctUntilChanged, skip } from 'rxjs/operators';
import { ApiUserService } from '../../service/user/api-user.service';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertNotificationModel } from '../../models/alerts/alert-notification-model';
import { AlertSubscriptionModel } from '../../models/alerts/alert-subscription-model';
import { AlertSubscriptionInputModel } from '../../models/alerts/alert-subscription-input-model';
import { SubscriptionComponent } from '../../dialog/subscription/subscription.component';
import { AlertService } from '../../service/alerts/alert.service';
import { AppComponent } from '../../app.component';
import { BookingService } from '../../service/booking/booking.service';
import { BookingDataTransferModel } from '../../models/booking-data-model';
import { BookingEditModel } from '../../models/bookings/booking-edit-model';

@Component({
  selector: 'app-booking-voyage-search',
  templateUrl: './booking-voyage-search.component.html',
  styleUrl: './booking-voyage-search.component.css'
})
export class BookingVoyageSearchComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  @ViewChild(MatPaginator) paginator = {} as MatPaginator;
  @ViewChild(MatSort) sort = {} as MatSort;
  dataSource = new MatTableDataSource();

  @Input() bookingData!: BookingEditModel;

  @Output() bookingDataOutput = new EventEmitter<BookingEditModel>();
  filter!: FilterData;
  filterParameter!: FilterData;
  savedFilters: DataFilter[] = [];
  currentFilter: DataFilter = new DataFilter();
  filterData: FilterData = {};
  isLoading = false;

  FilterEnum = FilterEnum;
  selectedButton = "";

  detailView = detailView;

  displayedColumns: ColumnSetup[] = [];
  extractedColumns: string[] = [];
  isSelected: boolean = false;

  scheduleList: ScheduleListModel[] = []

  dateFrom? = new Date;
  dateTo? = new Date;

  _snackBar: any;
  horizontalPosition: any;
  verticalPosition: any;

  constructor(private schedulesService: SchedulesService, private bookingService: BookingService, private cdr: ChangeDetectorRef, private communicationService: CommunicationService, private dialog: MatDialog, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private alertDataService: AlertService, private appComponent: AppComponent) {

  }

  ngOnInit(): void {
    this.loadData();

  }

  configureColumns(): void {
    this.displayedColumns = [
      { name: 'selected', header: '', type: 'bool', visible: true },
      { name: 'serviceName', header: 'Service Name', type: 'string', visible: true },
      { name: 'voyageNo', header: 'Voyage Number', type: 'string', visible: true },
      { name: 'loadPort', header: 'Load Port', type: 'string', visible: true },
      { name: 'carrier', header: 'Carrier', type: 'string', visible: true },
      { name: 'vesselName', header: 'Vessel Name', type: 'string', visible: true },
      { name: 'dischargePort', header: 'Discharge Port', type: 'string', visible: true },
      { name: 'stackEnd', header: 'Stack End', type: 'date', visible: true },
      { name: 'loadETD', header: 'Load ETD', type: 'date', visible: true },
    ]

    this.extractedColumns = this.displayedColumns.map(col => col.name);
  }

  async updateAndFilterData() {
    await this.updateData();
    this.dataSource.data = this.scheduleList.filter(data =>
      data.sailingScheduleHeaderCode === this.bookingData.vesselScheduleCode &&
      data.lineNumber === this.bookingData.vesselScheduleLineNumber &&
      data.loadPortCode === this.bookingData.loadPortCode
    );
  }

  //Load data with filter? - Do not load on first run
  loadData(): void {
    this.bookingService.addBookingSendFilterData$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (filterReceived) => {
          this.isLoading = true;
          this.currentFilter.filter = filterReceived.filter;
          this.currentFilter.id = filterReceived.filterId;
          this.dateFrom = filterReceived.startDate;
          this.dateTo = filterReceived.endDate;
          this.updateData();          
        }
      });

    if (this.bookingData) {
      if (this.bookingData.vesselScheduleCode !== '') {
        this.schedulesService.getScheduleList(this.currentFilter.id, this.currentFilter.filter, this.dateFrom).subscribe(data => {
          this.configureColumns();
          this.scheduleList = data;
          this.dataSource.data = data;
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.isLoading = false;
          this.dataSource.data = this.scheduleList.filter(data => data.sailingScheduleHeaderCode === this.bookingData.vesselScheduleCode && data.lineNumber === this.bookingData.vesselScheduleLineNumber && data.loadPortCode === this.bookingData.loadPortCode);
          this.dataSource.data[0]
          this.isSelected = true;
          this.bookingDataOutput.emit(this.bookingData);
        });
      }
    }
  }

  checkdata(): void {



  }

  //Call to get the schedule data
  updateData(filterData?: FilterData): void {

    this.schedulesService.getScheduleList(this.currentFilter.id, this.currentFilter.filter, this.dateFrom, this.dateTo).subscribe(data => {
      this.configureColumns();
      this.scheduleList = data;
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    });

    this.filterParameter = {};  

  }


  showLoading(show: boolean): void {
    this.appComponent.showLoading(show);
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  getExtractedColumns(columns: ColumnSetup[]) {
    return columns.map(col => col.name);

  }

  public selectRow(row: any): void {
    row.selected = !row.selected; // Toggle the selected value
    if (row.selected) {
      console.log('Checkbox is now selected');
      // Perform actions when the checkbox is selected

      this.isSelected = true
      this.dataSource.data = this.scheduleList.filter(data => data.sailingScheduleHeaderCode == row.sailingScheduleHeaderCode && data.lineNumber == row.lineNumber && data.loadPortCode === row.loadPortCode);

      this.bookingData.vesselScheduleCode = row.sailingScheduleHeaderCode;
      this.bookingData.vesselScheduleLineNumber = row.lineNumber;
      this.bookingData.carrierCode = row.carrierCode;
      this.bookingData.loadPortCode = row.loadPortCode;
      this.bookingData.dischargePortCode = row.dischargePortCode;


      this.bookingDataOutput.emit(this.bookingData);

    } else {
      console.log('Checkbox is now not selected');

      this.dataSource.data = this.scheduleList;

      this.bookingData.vesselScheduleCode = '';
      this.bookingData.vesselScheduleLineNumber = 0;
      this.bookingData.carrierCode = '';
      this.bookingData.loadPortCode ='';
      this.bookingData.dischargePortCode = '';

      this.bookingDataOutput.emit(this.bookingData);
      this.isSelected = false

    }
  } 


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
