<div class="detail-wrapper">


  <div class="detail-summary">
    <div class="detail-summary-heading">Summary</div>
    <div class="detail-summary-header-row">
      <ng-container *ngFor="let column of summaryDisplayedColumns">
        <div class="dv-timeline-data" *ngIf="column[0] !== 'actions'">
          <strong>{{ column[1] }}</strong>
        </div>
      </ng-container>
    </div>
    <div *ngFor="let row of alertData" class="dv-timeline-data-row" id="mobileCard">
      <ng-container *ngFor="let column of displayedColumns">
        <div class="dv-timeline-data" *ngIf="column[0] !== 'actions'">
          <ng-container *ngIf="column[0] === 'alertDate'">
            {{ getValueForRowColumn(row, column[0]) | date: 'dd MMM yyyy HH:mm:ss' }}
          </ng-container>
          <ng-container *ngIf="column[0] === 'details'">
            ________
          </ng-container>
          <ng-container *ngIf="column[0] !== 'details'&& column[0] !== 'alertDate'">
            {{getValueForRowColumn(row, column[0])}}
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="detail-summary">
    <div class="detail-summary-heading">Origin Port</div>
    <div class="detail-summary-header-row">
      <ng-container *ngFor="let column of originPortDisplayedColumns">
        <div class="dv-timeline-data" *ngIf="column[0] !== 'actions'">
          <strong>{{ column[1] }}</strong>
        </div>
      </ng-container>
    </div>
    <div *ngFor="let row of alertData" class="dv-timeline-data-row" id="mobileCard">
      <ng-container *ngFor="let column of displayedColumns">
        <div class="dv-timeline-data" *ngIf="column[0] !== 'actions'">
          <ng-container *ngIf="column[0] === 'alertDate'">
            {{ getValueForRowColumn(row, column[0]) | date: 'dd MMM yyyy HH:mm:ss' }}
          </ng-container>
          <ng-container *ngIf="column[0] === 'details'">
            ________
          </ng-container>
          <ng-container *ngIf="column[0] !== 'details'&& column[0] !== 'alertDate'">
            {{getValueForRowColumn(row, column[0])}}
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="detail-summary">
    <div class="detail-summary-heading">In Transit</div>
    <div class="detail-summary-in-transit-header-row">
      <ng-container *ngFor="let column of inTransitDisplayedColumns">
        <div class="dv-timeline-data" *ngIf="column[0] !== 'actions'">
          <strong>{{ column[1] }}</strong>
        </div>
      </ng-container>
    </div>
    <div *ngFor="let row of alertData" class="dv-timeline-data-row" id="mobileCard">
      <ng-container *ngFor="let column of displayedColumns">
        <div class="dv-timeline-data" *ngIf="column[0] !== 'actions'">
          <ng-container *ngIf="column[0] === 'alertDate'">
            {{ getValueForRowColumn(row, column[0]) | date: 'dd MMM yyyy HH:mm:ss' }}
          </ng-container>
          <ng-container *ngIf="column[0] === 'details'">
            ________
          </ng-container>
          <ng-container *ngIf="column[0] !== 'details'&& column[0] !== 'alertDate'">
            {{getValueForRowColumn(row, column[0])}}
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="detail-summary">
    <div class="detail-summary-heading">Destination Port</div>
    <div class="detail-summary-header-row">
      <ng-container *ngFor="let column of destinationPortDisplayedColumns">
        <div class="dv-timeline-data" *ngIf="column[0] !== 'actions'">
          <strong>{{ column[1] }}</strong>
        </div>
      </ng-container>
    </div>
    <div *ngFor="let row of alertData" class="dv-timeline-data-row" id="mobileCard">
      <ng-container *ngFor="let column of displayedColumns">
        <div class="dv-timeline-data" *ngIf="column[0] !== 'actions'">
          <ng-container *ngIf="column[0] === 'alertDate'">
            {{ getValueForRowColumn(row, column[0]) | date: 'dd MMM yyyy HH:mm:ss' }}
          </ng-container>
          <ng-container *ngIf="column[0] === 'details'">
            ________
          </ng-container>
          <ng-container *ngIf="column[0] !== 'details'&& column[0] !== 'alertDate'">
            {{getValueForRowColumn(row, column[0])}}
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>

</div>