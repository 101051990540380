import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommunicationService } from '../service/communication/communication.service';
import { BookingService } from '../service/booking/booking.service';
import { AddBookingEnum, BookingModel } from '../models/booking-data-model';
import { Alert } from '../models/alerts/alerts';
import { detailTabbedViewEnum } from '../models/detail-view-enum';
import { ScheduleLinesModel } from '../models/schedules/schedule-lines-model';
import { ScheduleListModel } from '../models/schedules/schedule-list-model';
import { ScheduleVoyageCoordinates } from '../models/schedules/schedule-voyage-coordinates';
import { AlertService } from '../service/alerts/alert.service';
import { SchedulesService } from '../service/schedules/schedules.service';
import L from 'leaflet';
import { BookingDataTransferModel } from '../models/booking-data-model';
import { Router } from '@angular/router';
import { BookingHeaderModel } from '../models/bookings/booking-header-model';
import { BookingLineModel } from '../models/bookings/booking-line-model';

@Component({
  selector: 'app-booking-detailed-view',
  templateUrl: './booking-detailed-view.component.html',
  styleUrl: './booking-detailed-view.component.css',
})
export class BookingDetailedViewComponent implements OnInit, AfterViewInit {

  private bookingParameter: string = '';
  public booking = new BookingHeaderModel;
  public bookingLines: BookingLineModel[] = [];

  detailTabbedViewEnum = detailTabbedViewEnum
  detailTabbedViewEnumSelected = detailTabbedViewEnum.detailsTab
  @Input() selectedTab = this.detailTabbedViewEnumSelected
  @ViewChild('mapContainer', { static: false }) mapContainer!: ElementRef;
  private map: any;
  private routePolyline: any;
  alertData: Alert[] = [];
  public scheduleLines: ScheduleLinesModel[] = [];
  private voyageCoordinates: ScheduleVoyageCoordinates[] = [];
  private sheduleParameter: string = '';
  public voyage = new ScheduleListModel();
  bookingTransfer: BookingDataTransferModel = new BookingDataTransferModel();
  constructor(private bookingService: BookingService, private schedulesService: SchedulesService, private alertDataService: AlertService, private communicationService: CommunicationService, private router: Router,) { }

  ngOnInit(): void {
    this.communicationService.detailViewParam.subscribe(param => { this.bookingParameter = param });
    this.getBookingDetail(this.bookingParameter);
    this.getBookingLines(this.bookingParameter);
    this.getAlertsForGRRef(this.bookingParameter);
    this.communicationService.detailedViewToggle$.subscribe(d => {
      this.selectedTab = d.detailTabbedViewEnum;
    });
  }

  ngAfterViewInit(): void {
  }

  private initMap(): void {

    this.map = L.map('map').setView([this.voyageCoordinates[0].latitude, this.voyageCoordinates[0].longitude], 2);

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    // Add route polyline
    this.routePolyline = L.polyline(this.voyageCoordinates.map(p => [p.latitude, p.longitude]), { color: '#162a3e', dashArray: '5, 10' }).addTo(this.map);

    tiles.addTo(this.map);

  }

  private getCoordinates(voyageParam: string): void {
    this.schedulesService.getVoyageCoordinates(voyageParam).subscribe(data => {
      this.voyageCoordinates = data;
      this.initMap();
    })

  }

  private getBookingDetail(bookingHeaderCode: string): void {
    this.bookingService.getBooking(bookingHeaderCode).subscribe(data => { this.booking = data })
  }

  private getBookingLines(bookingHeaderCode: string): void {
    this.bookingService.getBookingLines(bookingHeaderCode).subscribe(data => { this.bookingLines = data })
  }

  private getAlertsForGRRef(alertParameter: string): void {
    this.alertDataService.getAlertsForReference('Booking', alertParameter)
    .subscribe(data => { this.alertData = data })
    console.log(this.alertData);
  }

  public addBookingLine(): void {
    this.bookingTransfer = {
      appBookingCode: this.booking.appBookingCode,
      appBookingLineNumber: null,
      scheduleCode: null,
      scheduleLine: null,
      loadPortCode: null,
      bookingType: AddBookingEnum.addBookingLine
    }

    this.router.navigate(['/add-booking'], { state: { data: this.bookingTransfer } });
    this.closeRightDrawer();
  }


  closeRightDrawer(){
    this.communicationService.closeRightNav();
  }
}
