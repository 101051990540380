<div class="booking">
  <mat-card *ngFor="let line of bookingLineData" class="booking-detail-card">
    <div class="header-action-container">
      <div class="booking-line-title">Booking lines {{line.lineNo}}</div>
      <div class="edit-button" (click)="editBookingLine(line.lineNo)" >
        Edit <mat-icon>edit</mat-icon>
      </div>
    </div>
    <mat-card-content class="booking-detail-content">
      <div class="booking-line booking-line-item">
        <div class="col-md-4">
          <div>Booking ref: {{line.bookingRef}}</div>
          <div>Client ref: {{line.clientRef}}</div>
          <div>Container status: {{line.containerStatus}}</div>
          <div>App Booking status: {{line.appBookingStatus}}</div>
          <div>Rejection reason: {{line.rejectionReason}}</div>
        </div>

        <div class="col-md-4">
          <div>Discharge port: {{line.dischargePort}}</div>
          <div>Final destination: {{line.finalDestination}}</div>
          <div>Confirmed pack date: {{line.confirmedPackDate | date}}</div>
          <div>Commodity: {{line.commodity}}</div>
          <div>Loading point 1: {{line.loadingPoint1}}</div>
        </div>

        <div class="col-md-4">
          <div>Loading point 2: {{line.loadingPoint2}}</div>
          <div>Temperature: {{line.temperature}} °C</div>
          <div>Quantity: {{line.quantity}}</div>
          <div>Line no: {{line.lineNo}}</div>
          <div>Late stack request: {{line.lateStackRequest ? 'Yes' : 'No'}}</div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <div class="detailed-btn-download-group">
    <button class="add-booking-btn">
      <mat-icon>download</mat-icon>Download full booking
    </button>

    <button class="add-booking-btn">
      <mat-icon>download</mat-icon> Per load point 
    </button>
  </div>
</div>


