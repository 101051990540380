export interface IBookingLineModel {
  bookingHeaderCode: string;
  lineNo: number;
  appBookingCode: string;
  appBookingLineNumber: number;
  bookingRef: string;
  clientRef: string;
  containerStatus: string;
  appBookingStatus: string;
  rejectionReason: string | null;
  dischargePort: string;
  finalDestination: string;
  confirmedPackDate: Date | null;
  commodity: string;
  loadingPoint1: string;
  loadingPoint2: string;
  temperature: number;
  quantity: number;
  lateStackRequest: boolean;
}

export class BookingLineModel implements IBookingLineModel {
  bookingHeaderCode: string = '';
  lineNo: number = 0;
  appBookingCode: string = '';
  appBookingLineNumber: number = 0;
  bookingRef: string = '';
  clientRef: string = '';
  containerStatus: string = '';
  appBookingStatus: string = '';
  rejectionReason: string | null = null;
  dischargePort: string = '';
  finalDestination: string = '';
  confirmedPackDate: Date | null = null;
  commodity: string = '';
  loadingPoint1: string = '';
  loadingPoint2: string = '';
  temperature: number = 0;
  quantity: number = 0;
  lateStackRequest: boolean = false;
}
