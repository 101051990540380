import { Component, Input, OnInit } from '@angular/core';
import { detailTabbedViewEnum } from '../models/detail-view-enum';
import { CommunicationService } from '../service/communication/communication.service';
import { AlertService } from '../service/alerts/alert.service';
import { Alert } from '../models/alerts/alerts';
import { ShipmentModel } from '../models/shipment/shipment-data-model';
import { ShipmentService } from '../service/shipment/shipment.service';
import { DocumentService } from '../service/documents/document.service';
import { IDocument } from '../models/documents/document';
import { ChangeDetectorRef } from '@angular/core'; // Add this import
import { DocumentStatus } from '../enums/document-status.enum';
import { ScheduleVoyageCoordinates } from '../models/schedules/schedule-voyage-coordinates';
import { TransportMode } from '../enums/transport-mode.enum';

@Component({
  selector: 'app-detailed-shipment',
  templateUrl: './detailed-shipment.component.html',
  styleUrls: ['./detailed-shipment.component.css']
})
export class DetailedShipmentComponent implements OnInit {
  protected detailTabbedViewEnum = detailTabbedViewEnum
  protected detailTabbedViewEnumSelected = detailTabbedViewEnum.trackingTab
  protected alertData: Alert[] = [];
  protected shipmentData: ShipmentModel={
    consigneeCode: '',
    consignee: '',
    vesselVoyage: '',
    destination: '',
    bookingReference: '',
    shipmentStatus: '',
    bookingRef: '',
    transshipmentVessel: '',
    containerNumber: '',
    grReference: '',
    client: '',
    eta: new Date(),
    etd: new Date(),
    daysPriorToETA: 0,
    loadPort: '',
    dischargePort: '',
    shipmentStatusColor: '',
    indicators: '',
    hasAlerts: false,
    hasSubscriptions: false,
    DocumentStatus: DocumentStatus.Unknown
  };
  protected transportMode: TransportMode = TransportMode.Ocean;
  protected voyageCoordinates: ScheduleVoyageCoordinates[] = [
    {
        id: 0,
        voyageNo: "NZ123R|NZ123R",
        eta: new Date("2024-10-10T00:00:00"),
        location: "Durban",
        latitude: -29.866666,
        longitude: 31.016666
    },
    {
        id: 1,
        voyageNo: "NZ123R",
        eta: new Date("2024-11-11T00:00:00"),
        location: "Novorossiysk",
        latitude: 44.712538,
        longitude: 37.771743
    },
    {
        id: 2,
        voyageNo: "NZ123R",
        eta: new Date("2024-11-14T00:00:00")  ,
        location: "Vostochniy, Port",
        latitude: 42.734594,
        longitude: 133.067368
    },
    {
        id: 3,
        voyageNo: "NZ123R",
        eta: new Date("2024-11-28T00:00:00")    ,
        location: "Vladivostok",
        latitude: 43.117122,
        longitude: 131.896018
    },
    {
        id: 4,
        voyageNo: "NZ123R",
        eta: new Date("2024-12-08T00:00:00")      ,
        location: "Saint Petersburg",
        latitude: 59.821127,
        longitude: 30.388194
    },
    {
        id: 5,
        voyageNo: "NZ123R",
        eta: new Date("2024-12-11T00:00:00")      ,
        location: "Domodedovo Apt/Moscow",
        latitude: 55.414967,
        longitude: 37.899953
    }
];
  protected startLocation: L.LatLngExpression = [0, 0];
  protected endLocation: L.LatLngExpression = [0, 0];
  documentParameter: string = ''
  @Input() selectedTab = this.detailTabbedViewEnumSelected

  constructor(
    private communicationService: CommunicationService,
    private alertDataService: AlertService,
    private shipmentDataService: ShipmentService,
    private documentService: DocumentService,
    private cdr: ChangeDetectorRef 
  ) {}

  public ngOnInit(): void {
    this.communicationService.detailViewParam.subscribe(param => { 
      this.documentParameter = param; 
      this.getAlertsForGRRef(this.documentParameter);
      this.getShipemntsForGRRef(this.documentParameter);
    });
    
    this.documentService.currentDocuments$.subscribe(documents => {
      this.updateDocumentData(documents);
      // this.cdr.detectChanges(); // Trigger change detection after updating documents
    });
  }

  private getAlertsForGRRef(alertParameter: string): void {
    this.alertDataService.getAlertsForReference('Shipment', alertParameter)
      .subscribe(data => {
        this.alertData = data;
      });
    console.log(this.alertData);
  }

  private getShipemntsForGRRef(shipmentParameter: string): void {
    this.shipmentDataService.getShipmentsForGRRef(shipmentParameter).subscribe(
      (data) => {
        this.shipmentData = data[0]; 
        console.log('Just the data : ' + data[0].consigneeCode);
        console.log("shipmentData :" + this.shipmentData.bookingRef);
      });
  }

  private updateDocumentData(documents: IDocument[]): void {
    console.log('Updated documents:', documents);
    // Implement your logic to update the UI or state based on the documents
    // For example, you might want to refresh the displayed data or trigger a re-fetch of related data
  }

  protected getDelayInDays(eta: Date, etd: Date): number {
    const oneDay = 24 * 60 * 60 * 1000;
    const etaDate = new Date(eta).getTime();
    const etdDate = new Date(etd).getTime();
    
    return Math.round((etaDate - etdDate) / oneDay);
  }
}
