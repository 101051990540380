import { Component, ViewChild, OnInit, OnDestroy, inject, ChangeDetectorRef } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DocumentService} from '../service/documents/document.service';
import { IDocument, Document } from '../models/documents/document';
import { FormControl } from '@angular/forms';
import { CommunicationService } from '../service/communication/communication.service';
import { FilterData } from '../models/list-model';
import { ColumnSetup } from '../models/column-setup'
import { FilterEnum } from '../models/filter-enum';
import { detailTabbedViewEnum, detailView } from '../models/detail-view-enum';
import { DataFilter, FilterTransferModel } from '../models/filter-models';
import { Subject, of } from 'rxjs';
import { takeUntil, catchError, distinctUntilChanged, skip } from 'rxjs/operators';
import { ApiUserService } from '../service/user/api-user.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AppComponent } from '../app.component';
import { SubscriptionComponent } from '../dialog/subscription/subscription.component';
import { AlertNotificationModel } from '../models/alerts/alert-notification-model';
import { AlertSubscriptionInputModel } from '../models/alerts/alert-subscription-input-model';
import { AlertSubscriptionModel } from '../models/alerts/alert-subscription-model';
import { AlertService } from '../service/alerts/alert.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.css']
})
export class DocumentListComponent implements OnInit{

  private apiUserService = inject(ApiUserService)
  private destroy$ = new Subject<void>();
  isCustUser: boolean = false;

  @ViewChild(MatPaginator) paginator = {} as MatPaginator;
  @ViewChild(MatSort) sort = {} as MatSort;
  dataSource = new MatTableDataSource<Document>();

  filter!: FilterData;
  filterParameter!: FilterData;
  savedFilters: DataFilter[] = [];
  currentFilter: DataFilter = new DataFilter();
  filterData: FilterData = {};
  FilterEnum = FilterEnum;
  selectedButton = "";
  detailView = detailView;
  detailTabbedViewEnum = detailTabbedViewEnum;
  displayedColumns: ColumnSetup[] = [];
  extractedColumns: string[] = [];
  documentList: IDocument[] = [];
  templates: AlertSubscriptionModel[] = [];
  _snackBar: any;
  horizontalPosition: any;
  verticalPosition: any;
  //subscribedTemplatesMap = new Map<string, boolean>(); // To track hasSubscribedTemplates status

  constructor(
    private alertDataService: AlertService,
    private documentService: DocumentService,
    private dialog: MatDialog,
    private communicationService: CommunicationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private appComponent: AppComponent,
    private cdr: ChangeDetectorRef
  ) {
    this.matIconRegistry.addSvgIcon(
      'Subscribe',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/Subscribe.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewDocuments',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewDocuments.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewAlerts',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewAlerts.svg')
    )

  }

  ngOnInit(): void {
    this.updateCurrentFilter(0, '', {});

    this.documentService.filterNameSaved$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (t) => {
          this.loadSavedFilters();
          this.updateCurrentFilter(0, t, {});
        },
        error: (error) => {
          console.error('Error handling filter name saved event', error);
          window.alert('Failed to handle filter name saved event. Please try again later.');
        }
      });

    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isCustUser = this.apiUserService.IsCustUser;
          }
      });

    this.isCustUser = this.apiUserService.IsCustUser;

    this.loadSavedFilters();
    this.updateData();

    this.documentService.sendActiveFilterToList$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
          next: (filterReceived) => {
            this.updateCurrentFilter(filterReceived.id, filterReceived.filterName, filterReceived.filter)
            this.updateData(this.currentFilter.filter);
          }
      });
  }

  configureColumns(): void {
    this.displayedColumns = [
      { name: 'id', header: 'Id', type: 'string', visible: false },
      { name: 'documentStatusColor', header: '', type: 'string', visible: true },
      { name: 'customerCode',header:'Customer',type:'string',visible:!this.isCustUser},
      { name: 'grReference', header:  'GR Ref No', type: 'string', visible: true },
      { name: 'indicators', header: 'Indicators', type: 'string', visible: true },
      { name: 'clientReference', header: 'Client Reference', type: 'string', visible: true },
      { name: 'bookingReference', header: 'Booking Reference', type: 'string', visible: true },
      { name: 'dhlReference', header: 'DHL Reference', type: 'string', visible: true },
      { name: 'documentType', header: 'Document Type', type: 'string', visible: true },
      { name: 'documentApprovalStatus', header: 'Approval Status', type: 'string', visible: true },
      { name: 'documentReasonCode', header: 'Reason Code', type: 'string', visible: false },
      { name: 'documentReasonDetail', header: 'Reason Detail', type: 'string', visible: false },
      { name: 'documentPartition', header: 'Document Partition', type: 'string', visible: false },
      { name: 'documentAttachmentSystemId', header: 'Attachment SystemId', type: 'string', visible: true },
      { name: 'actions', header: 'Action', type: 'string', visible: true }
    ];
    this.extractedColumns = this.displayedColumns.map(col => col.name);
  }

  updateData(filterData?: FilterData): void {

    this.documentService.getDocumentList(this.currentFilter.id, this.currentFilter.filter).subscribe(data => {
      this.configureColumns();
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });

    this.filterParameter = {};
  }

  onOpenFilter(openFiler: FilterEnum) {
    this.communicationService.toggleFilter(openFiler);
    this.sendingActiveFilter();
  }

  loadSavedFilters() {
    this.documentService.getFilters()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          this.savedFilters = data;
          if (this.currentFilter.filterName !== "") {
            let filterId = this.getFilterIdByName(this.currentFilter.filterName);
            this.updateCurrentFilter(filterId, this.currentFilter.filterName, this.getSavedFilterFromId(filterId));
            this.updateData();
          }
        }
      });
  }

  processFilterData(key: string, value: string) {
    const dataArray = value
      .split(',')
      .filter(item => item.trim() !== '');
    this.filterParameter[key] = dataArray;
  }

  setupFilter(column: string) {
    this.dataSource.filterPredicate = (d: any, filter: string) => {
      const textToSearch = d[column] && d[column].toLowerCase() || '';
      return textToSearch.indexOf(filter) !== -1;
    };
  }

  applyFilter(filterId: number): void {
    this.filterData = {};

    if (this.currentFilter.id === filterId) {
        this.updateCurrentFilter(0, '', {});
    }
    else {
      this.updateCurrentFilter(filterId, this.getSavedFilterNameFromId(filterId), this.getSavedFilterFromId(filterId))
    }
    this.updateData(this.currentFilter.filter)
  }

  updateCurrentFilter(id: number, name: string, filter: FilterData) {
    this.currentFilter.id = id;
    this.currentFilter.filterName = name;
    this.currentFilter.filter = filter;
  }

  getSavedFilterNameFromId(filterId: number): string {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filterName : '';
  }

  getSavedFilterFromId(filterId: number): FilterData {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filter : {};
  }

  getFilterIdByName(filterName: string): number {
    const filter = this.savedFilters.find(f => f.filterName === filterName);
    return filter ? filter.id : 0;
  }

  sendingActiveFilter() {
    this.documentService.sendingActiveFilterToFilter(this.currentFilter)
  }

  selectButton(button: string) {
    if (this.selectedButton === button) {
      this.selectedButton = '';
      this.applyStatusFilter('');
    } else {
      this.selectedButton = button;
      this.applyStatusFilter(button);
    }
  }

  applyStatusFilter(filterValue: string) {
    if (!filterValue) {
      this.dataSource.filter = '';
    } else {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  private createNotificationModel(row: any): AlertNotificationModel {
    return {
      AlertCode: '',
      CustomerCode: row.customerCode,
      GRReference: String(row.grReference),
      TemplateGroup: 'Document'
    } as AlertNotificationModel;
  }

  viewDetails(row: any, view: detailView, tab: detailTabbedViewEnum): void {
    this.communicationService.toggleDetailView(view, tab);
    this.communicationService.getDetailViewParameter(row['grReference'])
  }

  //-----------------------------------------------------------------

  subscribeToAlert(row: any): void {
    const notificationModel = this.createNotificationModel(row);

    this.showLoading(true);

    this.alertDataService.getAlertSubscriptionTemplates(notificationModel)
      .pipe(
        catchError(error => {
          console.error('Error fetching subscription templates:', error);
          return of([]);
        })
      )
      .subscribe((subscriptionTemplates: AlertSubscriptionModel[]) => {

        this.showLoading(false);

        const subscriptionInput = new AlertSubscriptionInputModel();
        subscriptionInput.notificationModel = notificationModel;
        subscriptionInput.templates = subscriptionTemplates;

        const dialogRef = this.dialog.open(SubscriptionComponent, {
          data: { subscriptionList: subscriptionTemplates }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {

            subscriptionInput.templates = result;

            this.alertDataService.updateAlertSubscription(subscriptionInput)
              .pipe(
                catchError(error => {
                  console.error('Error updating subscriptions:', error);
                  return of(null);
                })
              )
              .subscribe(response => {
                if (response) {
                  this.updateData();
                }
              });
          }
        });
      });
  }

  showLoading(show: boolean): void {
    this.appComponent.showLoading(show);
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }
  isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  getBackgroundColor(cellValue: string): string {
    switch (cellValue) {
      case 'Approved':
        return '#36DE6F';
      case 'AwaitingApproval':
        return '#FFD34F';
      default:
        return '#DC6868';
    }
  }

}
