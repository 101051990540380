<div class="mat-elevation-z2  custom-card-radius" *ngIf="isMinWidth(760)">
  <table mat-table class="full-width-table" [dataSource]="shipmentDataSource" matSort aria-label="Elements">

   <ng-container *ngFor="let column of displayedColumns">
    <ng-container [matColumnDef]="column.name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!column.visible">
        {{ column.header }}
      </th>
      <td mat-cell *matCellDef="let row" [hidden]="!column.visible"
      [ngStyle]="column.name === 'shipmentStatusColor' ? {'background-color': getBackgroundColor(row['shipmentStatus'])} : {}">
        
        <ng-container *ngIf="column.name !== 'actions'">
          <ng-container *ngIf="column.name !== 'shipmentStatusColor' && column.name !== 'indicators' && column.name !== 'eta' && column.name !== 'etd'">
            {{ row[column.name] }}
          </ng-container>
          <ng-container *ngIf="column.name === 'eta'">
            {{ row[column.name] | date: 'dd MMM yyyy' }}
          </ng-container>
          <ng-container *ngIf="column.name === 'etd'">
            {{ row[column.name] | date: 'dd MMM yyyy' }}
          </ng-container>
          <ng-container *ngIf="column.name === 'indicators'">
            <button [ngClass]="row.hasSubscriptions ? 'indicator-btn-subbed' : 'indicator-btn'"  (click)="subscribeToAlert(row)">
              <mat-icon svgIcon="Subscribe"></mat-icon>
            </button>
            <button [ngClass]="row.hasAlerts ? 'indicator-btn-alerts-unread' : 'indicator-btn'"  (click)="viewDetails(row, detailView.shipmentDetail, detailTabbedViewEnum.alertTab)">
              <mat-icon svgIcon="ViewAlerts"></mat-icon>
            </button>
            <button [ngClass]="fetchDocumentStatusClass(row.DocumentStatus)" (click)="viewDetails(row, detailView.shipmentDetail, detailTabbedViewEnum.documentTab)">
              <mat-icon svgIcon="ViewDocuments"></mat-icon>
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="column.name === 'actions'">
          <button class="detailed-view-btn" (click)="viewDetails(row, detailView.shipmentDetail, detailTabbedViewEnum.trackingTab)">
            View Details <mat-icon>arrow_right_alt</mat-icon>
          </button>
        </ng-container>
      </td>
    </ng-container>
  </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="getExtractedColumns(displayedColumns)"></tr>
      <tr mat-row *matRowDef="let row; columns: getExtractedColumns(displayedColumns);"></tr>
    </table>
  
    <mat-paginator #paginator
                   [length]="shipmentDataSource.data.length"
                   [pageIndex]="0"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 20]"
                   aria-label="Select page">
    </mat-paginator>
  </div>
  
  <div *ngIf="!isMinWidth(760)">
    <div *ngFor="let row of shipmentDataSource.filteredData" class="card" id="mobileCard" 
         [ngStyle]="{'border-left-color': getBackgroundColor(row.shipmentStatus)}">
      <ng-container *ngFor="let column of displayedColumns">
        <div *ngIf="column.name !== 'actions' && column.name!=='shipmentStatusColor'&& column.name!=='indicators'">
          <strong>{{ column.header }} <span class="card-line">:</span></strong>
          
          <ng-container *ngIf="column.name === 'eta'">
            {{getValueForRowColumn(row, column.name)| date: 'dd MMM yyyy' }}
          </ng-container>
          <ng-container *ngIf="column.name === 'etd'">
            {{ getValueForRowColumn(row, column.name) | date: 'dd MMM yyyy' }}
          </ng-container>
          <ng-container *ngIf="column.name !== 'indicators' && column.name !== 'eta' && column.name !== 'etd'">
            {{ getValueForRowColumn(row, column.name) }}
          </ng-container>
          
        </div>
      </ng-container>
      <div class="card-actions-section">
        <div class="indicators_card">
          <button [ngClass]="row.hasSubscriptions ? 'indicator-btn-subbed' : 'indicator-btn'"  (click)="subscribeToAlert(row)">
             <mat-icon svgIcon="Subscribe"></mat-icon>
          </button>
          <button [ngClass]="row.hasAlerts ? 'indicator-btn-alerts-unread' : 'indicator-btn'" (click)="viewDetails(row, detailView.shipmentDetail,detailTabbedViewEnum.alertTab)">
            <mat-icon svgIcon="ViewAlerts"></mat-icon>
          </button>
          <button [ngClass]="fetchDocumentStatusClass(row.DocumentStatus)" (click)="viewDetails(row, detailView.shipmentDetail,detailTabbedViewEnum.documentTab)">
            <mat-icon svgIcon="ViewDocuments"></mat-icon>
          </button>
      </div>
      <button class="detailed-view-btn" color="primary" (click)="viewDetails(row, detailView.shipmentDetail,detailTabbedViewEnum.trackingTab)">
        View Details <mat-icon>arrow_right_alt</mat-icon>
      </button>
      </div>

  </div>
</div>
