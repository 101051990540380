import { Component, Input, OnInit } from '@angular/core';
import { TransportMode } from '../enums/transport-mode.enum';
import { ScheduleVoyageCoordinates } from '../models/schedules/schedule-voyage-coordinates';

@Component({
  selector: 'app-tracking-map',
  templateUrl: './tracking-map.component.html',
  styleUrls: ['./tracking-map.component.css']
})
export class TrackingMapComponent implements OnInit {

  @Input() vehicleLocation!: L.LatLngExpression;
  @Input() startLocation!: L.LatLngExpression;
  @Input() endLocation!: L.LatLngExpression;  // Ensure this is passed to all child components
  @Input() mode!: TransportMode;
  @Input() voyageCoordinates: ScheduleVoyageCoordinates[] = [];

  TransportMode = TransportMode;

  constructor() { }

  ngOnInit(): void {
    console.log("this.mode", this.mode);
   }
}
