import { Component, Input } from '@angular/core';
import { ScheduleLinesModel } from '../models/schedules/schedule-lines-model';

@Component({
  selector: 'app-schedules-details-tab',
  templateUrl: './schedules-details-tab.component.html',
  styleUrl: './schedules-details-tab.component.css'
})
export class SchedulesDetailsTabComponent {
@Input() scheduleLines!: ScheduleLinesModel[];

displayedColumns = [
  ['portCode', 'Port Code'],
  ['eta', 'ETA'],
  ['etd', 'ETD'],
  ['duration', 'Arrival Days'],
 // ['report', 'Report']
];

getValueForRowColumn(row: any, columnName: string): any {
  return row[columnName];
}

getExtractedColumns(columns: string[][]) {
  return columns.map(col => col[0]);

}

public getTimeDifference(eta: string | null): string {
  const today = new Date();

  if (eta) {
    const etaDate = new Date(eta);
    
    const timeDifference = etaDate.getTime() - today.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
    
    if (daysDifference === 0) {
      return 'Today';
    } else if (daysDifference > 0) {
      return `In ${daysDifference} day${daysDifference !== 1 ? 's' : ''}`;
    } else {
      return `${Math.abs(daysDifference)} day${Math.abs(daysDifference) !== 1 ? 's' : ''} ago`;
    }
  } else {
    return 'N/A';
  }

}

isMinWidth(minWidth: number): boolean {
  return window.innerWidth >= minWidth;
}

}
