import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, OnDestroy, inject } from '@angular/core';
import { BookingService } from '../service/booking/booking.service';
import { FilterData } from '../models/list-model';
import { FormControl} from '@angular/forms';
import { LookupModel } from '../models/lookup-model';
import { DataFilter } from '../models/filter-models';
import { MatDialog} from '@angular/material/dialog';
import { InformationComponent } from '../dialog/information/information.component';
import { LookupService } from '../service/lookup/lookup.service';
import { Observable, Subject } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { ApiUserService } from '../service/user/api-user.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {MatSnackBar,MatSnackBarHorizontalPosition,MatSnackBarVerticalPosition,} from '@angular/material/snack-bar';
import { CommunicationService } from '../service/communication/communication.service';

@Component({
  selector: 'app-bookingfilter',
  templateUrl: './bookingfilter.component.html',
  styleUrls: ['./bookingfilter.component.css', '../filter-sidenav/filter-sidenav.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class BookingfilterComponent implements OnInit, OnDestroy {

  private apiUserService = inject(ApiUserService)
  private destroy$ = new Subject<void>();
  isCustUser: boolean = false;

  customers = new FormControl<LookupModel[]>([]);
  private _snackBar = inject(MatSnackBar);
  loadPorts = new FormControl<LookupModel[]>([]);
  markets = new FormControl<LookupModel[]>([]);
  carriers = new FormControl<LookupModel[]>([]);
  latestacks = new FormControl();

  grRefNos = new FormControl('');
  savedFilterName = new FormControl('');

  customersMultiFilter = new FormControl<string>('');
  loadPortsMultiFilter = new FormControl<string>('');
  marketsMultiFilter = new FormControl<string>('');
  carriersMultiFilter = new FormControl<string>('');

  filterData: FilterData = {};
  filterName: string = '' ;
  datafilter: DataFilter = new DataFilter();
  savedFilters: DataFilter[] = [];

  filteredCustomers!: Observable<LookupModel[]>;
  filteredLoadPorts!: Observable<LookupModel[]>;
  filteredMarkets!: Observable<LookupModel[]>;
  filteredCarriers!: Observable<LookupModel[]>;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  bookingCustomer: LookupModel[] = [];
  bookingDataLoadPort: LookupModel[] = [];
  bookingDataCarrier: LookupModel[] = [];
  bookingDataMarket: LookupModel[] = [];
  checkedItems: Set<string> = new Set<string>();
  bookingDatalatestack: string[] = ['True', 'False'];

  constructor(private communicationService: CommunicationService,private domSanitizer: DomSanitizer, private bookingDataService: BookingService, private lookupService: LookupService, private cdr: ChangeDetectorRef, private dialog: MatDialog, private matIconRegistry: MatIconRegistry) {
      this.matIconRegistry.addSvgIcon('backspace', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/backspace.svg'));
   }

  ngOnInit(): void {

    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isCustUser = this.apiUserService.IsCustUser;
          }
      });

    this.isCustUser = this.apiUserService.IsCustUser;
    this.loadBookingData();   
  }

  loadBookingData(): void {

    this.bookingDataService.sendActiveFilterToFilter$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.datafilter = data;
          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Filter data', error)
      });

    this.lookupService.getCustomers(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.bookingCustomer = data;
          this.customers.setValue(this.bookingCustomer.filter(customer => customer.isSelected));
          this.filteredCustomers = this.customersMultiFilter.valueChanges.pipe(
            startWith(''), 
            map(searchText => this.filterCustomers(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Load Port data', error)
        });

    this.lookupService.getLoadPorts(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.bookingDataLoadPort = data;
          this.loadPorts.setValue(this.bookingDataLoadPort.filter(port => port.isSelected));
          this.filteredLoadPorts = this.loadPortsMultiFilter.valueChanges.pipe(
            startWith(''), 
            map(searchText => this.filterLoadPorts(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Load Port data', error)
        });
    
    this.lookupService.getCarriers(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next:(data) => {
          this.bookingDataCarrier = data;
          this.carriers.setValue(this.bookingDataCarrier.filter(port => port.isSelected));
          this.filteredCarriers = this.carriersMultiFilter.valueChanges.pipe(
            startWith(''), 
            map(searchText => this.filterCarriers(searchText ?? ''))
          );
      
          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Carrier data', error)
      });

    this.lookupService.getMarkets(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next:(data )=> {
          this.bookingDataMarket = data;
          this.markets.setValue(this.bookingDataMarket.filter(port => port.isSelected));
          this.filteredMarkets = this.marketsMultiFilter.valueChanges.pipe(
            startWith(''), 
            map(searchText => this.filterMarkets(searchText ?? ''))
          );
          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Market data', error)
      });
  
    let lateStackRestore = this.datafilter.filter['latestack']?.toString();
    this.latestacks.setValue(lateStackRestore);

    let grRefNosRestore = this.datafilter.filter['grRefNo']?.toString() ?? null;
    if(grRefNosRestore != null){
      this.grRefNos.setValue(grRefNosRestore);
    }

    if (this.datafilter.id !== 0) {
      this.filterName = this.datafilter.filterName;
      this.savedFilterName.setValue(this.filterName);
    }
  }

  clearLookupFilter(control: FormControl): void {
    control.reset();
    control.setValue([]);
    console.log('Filter cleared:', control);
  }

  save(): void {
    if (!this.savedFilterName.value) {
      // Optionally, you can show an error message here
      console.error('Filter name cannot be empty');
      return;
    }

    this.createFilter();
    this.filterName = this.savedFilterName.value.toString();
    this.bookingDataService.saveBookingFilter(this.filterName, this.filterData).subscribe(_ => {
      this.bookingDataService.filterNameSavedSend(this.filterName);
      this.bookingDataService.getFilters().subscribe(result => {
        this.savedFilters = result;
        const savedFil = this.savedFilters.find(f => f.filterName === this.filterName);
        this.datafilter.id = savedFil!.id;
        this.datafilter.filterName = savedFil!.filterName;
        this.datafilter.filter = savedFil!.filter;
      });
    });
    this.openSnackBar( 'Your filter '+this.filterName+' has been created');
  }

  clearAllFilters(): void {
    this.clearLookupFilter(this.loadPorts);
    this.clearLookupFilter(this.carriers);
    this.clearLookupFilter(this.markets);
    this.clearLookupFilter(this.customers);
    this.clearFilter(this.latestacks);
    this.clearFilter(this.grRefNos);
    this.applyFilters();
    this.openSnackBar( 'Your filters have been cleared');
  }

  clearFilter(control: FormControl) {
    control.reset();
    control.setValue([]);
  }

  private filterCustomers(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingCustomer;
    }
    return this.bookingCustomer.filter(customer =>
      customer.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterLoadPorts(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataLoadPort;
    }
    return this.bookingDataLoadPort.filter(loadPort =>
      loadPort.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterMarkets(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataMarket;
    }
    return this.bookingDataMarket.filter(loadPort =>
      loadPort.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterCarriers(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.bookingDataCarrier;
    }
    return this.bookingDataCarrier.filter(loadPort =>
      loadPort.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  get isSavedFilterNameEmpty(): boolean {
    return !this.savedFilterName.value;
  }

  deleteFilter(): void {
    let filterName = this.datafilter.filterName;
    
    this.bookingDataService.deleteBookingFilter(this.datafilter.id).subscribe(result => {
      this.bookingDataService.filterNameSavedSend('');
      this.savedFilterName.setValue('');
      this.datafilter.id = 0;
      this.datafilter.filterName = '';
      this.openSnackBar( 'Your filter, '+filterName+ ' has been deleted');
      console.log(result);
    });
  }

  openSnackBar(message:string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
        duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  applyFilters(): void {
    this.createFilter();
    this.bookingDataService.transferFilterData(this.filterData);
    this.closeRightDrawer();
    this.openSnackBar( 'Your filter has been applied');
  }

  createFilter(): void {
    if (this.customers.value != null) {
      const customerCodes = this.parseFilterSelections(this.customers.value);
      this.processFilterData('customerCode', customerCodes);
    }
    if (this.loadPorts.value != null) {
      const loadPortCodes = this.parseFilterSelections(this.loadPorts.value);
      this.processFilterData('loadPortCode', loadPortCodes);
    }
    if (this.markets.value != null) {
      const marketCodes = this.parseFilterSelections(this.markets.value);
      this.processFilterData('marketCode', marketCodes);
      }
    if (this.carriers.value != null) {
      const carrierCodes = this.parseFilterSelections(this.carriers.value);
      this.processFilterData('carrierCode', carrierCodes);
    }
 
    if (this.latestacks.value != null) {
      this.processFilterData('latestack', this.latestacks.value?.toString());
    }
    if (this.grRefNos.value != null) {
      this.processFilterData('grRefNo', this.grRefNos.value?.toString());
    }
  }

  parseFilterSelections(selections: LookupModel[]): string {
  const codes: string[] = selections.map(selection => selection.code);
  return codes.join(',');
  }

  processFilterData(key: string, value: string) {
    const dataArray = value
      .split(',')
      .filter(item => item.trim() !== '');

    this.filterData[key] = dataArray;
  }

  showInformation(result: any, message: string | null): void {
    const dialogRef = this.dialog.open(InformationComponent, {
      width: '300px',
      height: '250px',
      data: { resultStatus: result, showMessage: message },
    });
  }

  showError(message: string): void {
    console.error(message);
    this.showInformation('Document rejection Failed', message)
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

    //TODO: Fine a way to get this window to close without forcing it closed using CDR
  //Application : When a user clicks on apply filter this filter side nav needs to close
  //Scale: P3 None Critical
  closeRightDrawer(){
    this.communicationService.closeRightNav();
  }
}
