export interface IBookingHeaderModel {
  bookingHeaderCode: string;
  appBookingCode: string;
  customerCode: string;
  customer: string;
  grRefNo: string;
  appBookingStatus: string;
  loadPortCode: string;
  loadPort: string;
  carrierCode: string;
  carrier: string;
  serviceCode: string;
  serviceName: string;
  stackStart: Date;
  stackEnd: Date;
  sailDate: Date;
}
export class BookingHeaderModel implements IBookingHeaderModel {
  bookingHeaderCode: string = '';
  appBookingCode: string = '';
  customerCode: string = '';
  customer: string = '';
  grRefNo: string = '';
  appBookingStatus: string = '';
  loadPortCode: string = '';
  loadPort: string = '';
  carrierCode: string = '';
  carrier: string = '';
  serviceCode: string = '';
  serviceName: string = '';
  stackStart: Date = new Date();
  stackEnd: Date = new Date();
  sailDate: Date = new Date();
}
