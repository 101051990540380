import { Component, Input, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { ScheduleVoyageCoordinates } from '../../models/schedules/schedule-voyage-coordinates';

@Component({
  selector: 'app-ocean-tracking',
  templateUrl: './ocean-tracking.component.html',
  styleUrls: ['./ocean-tracking.component.css']
})
export class OceanTrackingComponent implements OnInit {

  private map!: L.Map;
  private shipMarker!: L.Marker;

  @Input() vehicleLocation!: L.LatLngExpression;
  @Input() startLocation!: L.LatLngExpression;
  @Input() voyageCoordinates!: ScheduleVoyageCoordinates[] ;
  @Input() endLocation!: L.LatLngExpression; // Already included end location

  ngOnInit(): void {
    this.initMap();
  }

  private initMap(): void {
    this.map = L.map('map').setView(this.startLocation, 3);

    L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: 'Map data &copy; OpenStreetMap contributors',
    }).addTo(this.map);

    this.shipMarker = L.marker(this.startLocation, {
      icon: L.icon({
        iconUrl: 'assets/shipIcon.svg',
        iconSize: [32, 32],
        iconAnchor: [16, 16]
      })
    }).addTo(this.map).bindPopup('Ship Location').openPopup();

    // Parse voyageCoordinates to draw the route
    this.drawSeaRouteFromCoordinates(this.voyageCoordinates);
   // this.drawSeaRoute(this.startLocation, this.endLocation);
   // Ensure map is resized correctly
setTimeout
(
() =>
{       this.map.
invalidateSize
();     }, 0);
// Delay resizing until after the map has been initialized
  }

  private drawSeaRoute(start: L.LatLngExpression, end: L.LatLngExpression): void {
    L.polyline([start, end], { color: '#162a3e', dashArray: '5, 10' }).addTo(this.map);
    console.log('start, end', start, end);
    //L.pathCurve([start, end], { color: 'blue', dashArray: '5, 10' }).addTo(this.map);
  }

  private drawSeaRouteFromCoordinates(coordinates: ScheduleVoyageCoordinates[]): void {
    const path: L.LatLngExpression[] = coordinates.map(p => [p.latitude, p.longitude] as L.LatLngTuple);
    // L.pathCurve(path, { color: '#162a3e', dashArray: '5, 10' }).addTo(this.map);
      L.polyline(path, { color: '#162a3e', dashArray: '5, 10' }).addTo(this.map);
  }
}
