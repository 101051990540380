import { Component, Input, OnInit, AfterViewInit, OnChanges, SimpleChanges, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { SchedulesService } from '../service/schedules/schedules.service';
import { ScheduleListModel } from '../models/schedules/schedule-list-model';
import { ScheduleVoyageCoordinates } from '../models/schedules/schedule-voyage-coordinates';
import { CommunicationService } from '../service/communication/communication.service';
import { detailTabbedViewEnum } from '../models/detail-view-enum';
import { Alert } from '../models/alerts/alerts';
import { AlertService } from '../service/alerts/alert.service';
import { AddBookingEnum, BookingDataTransferModel } from '../models/booking-data-model';
import { Router } from '@angular/router';
import * as L from 'leaflet';
import { ScheduleLinesModel } from '../models/schedules/schedule-lines-model';
import { TransportMode } from '../enums/transport-mode.enum';

@Component({
  selector: 'app-schedules-detail-view',
  templateUrl: './schedules-detail-view.component.html',
  styleUrls: ['./schedules-detail-view.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SchedulesDetailViewComponent implements OnInit, AfterViewInit {

  detailTabbedViewEnum = detailTabbedViewEnum;
  detailTabbedViewEnumSelected = detailTabbedViewEnum.trackingTab;
  @Input() selectedTab = this.detailTabbedViewEnumSelected;
  @ViewChild('mapContainer', { static: false }) mapContainer!: ElementRef;
  private map: any;
  private routePolyline: any;
  public alertData: Alert[] = [];
  public scheduleLines: ScheduleLinesModel[] = [];
  protected voyageCoordinates: ScheduleVoyageCoordinates[] = [];

  private scheduleHeaderCode: string = '';
  private carrierCode: string = '';
  private scheduleLoadPortCode: string = '';
  private dischargePortCode: string = '';
  private scheduleLineNumber: number = 0;

  public schedule = new ScheduleListModel();
  public bookingTransfer = new BookingDataTransferModel();
  public transportMode: TransportMode = TransportMode.Ocean;

  public startLocation: L.LatLngExpression = [0, 0];
  public endLocation: L.LatLngExpression = [0, 0];

  constructor(
    private schedulesService: SchedulesService,
    private alertDataService: AlertService,
    private communicationService: CommunicationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.communicationService.detailViewParam.subscribe(param => {
      var paramParts = param.split('|');
      this.scheduleHeaderCode = paramParts[0];
      this.carrierCode = paramParts[1];
      this.scheduleLoadPortCode = paramParts[2];
      this.dischargePortCode = paramParts[3];
    });

    this.communicationService.detailedViewToggle$.subscribe(d => {
      this.selectedTab = d.detailTabbedViewEnum;
    });
  }

  ngAfterViewInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.getScheduleListDetail(this.scheduleHeaderCode, this.carrierCode, this.scheduleLoadPortCode, this.dischargePortCode);
    this.getScheduleLines(this.scheduleHeaderCode);
    this.getCoordinates(this.scheduleHeaderCode);
    this.getAlertsForGRRef(this.scheduleHeaderCode);
  }

  protected mapTabClicked(): void {
   this.loadData();
  } 

  public initMap(): void {
    if (this.mapContainer && this.voyageCoordinates.length > 0) {

      if (this.map) {
        this.map.remove();
      }

      if (this.schedule.vesselName.includes('AIRFREIGHT')) {
        this.transportMode = TransportMode.Air;
      } else {
        this.transportMode = TransportMode.Ocean; // Set default or other modes as needed
      }

      // Initialize the map
      this.map = L.map(this.mapContainer.nativeElement).setView(
        [this.voyageCoordinates[0].latitude, this.voyageCoordinates[0].longitude], 2
      );

      const tiles = L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
        maxZoom: 18,
        minZoom: 3,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      });

      // Add route polyline
      // this.routePolyline =
       L.polyline(
        this.voyageCoordinates.map(p => [p.latitude, p.longitude]),
        { color: '#162a3e', dashArray: '5, 10' }
      ).addTo(this.map);

      tiles.addTo(this.map);
      this.map.invalidateSize(); // Ensure map is resized correctly

      console.log('Map initialized with coordinates:', this.voyageCoordinates);
    } else {
      console.warn('Map container or voyage coordinates are not available');
    }
  }

  private getCoordinates(scheduleHeaderCode: string): void {    
    this.schedulesService.getVoyageCoordinates(scheduleHeaderCode).subscribe(data =>
    {
      this.voyageCoordinates = data;
      console.log('voyageCoordinates', this.voyageCoordinates);

      this.startLocation = [this.voyageCoordinates[0].latitude, this.voyageCoordinates[0].longitude];
      this.endLocation = [this.voyageCoordinates[this.voyageCoordinates.length - 1].latitude, this.voyageCoordinates[this.voyageCoordinates.length - 1].longitude];

      if (this.selectedTab === this.detailTabbedViewEnum.trackingTab) {
        this.initMap();
      }
    });
  }

  private getScheduleLines(scheduleHaderCode: string): void {
    this.schedulesService.getScheduleLines(scheduleHaderCode).subscribe(data => { this.scheduleLines = data });
  }

  private getScheduleListDetail(scheduleHaderCode: string, carrierCode: string, scheduleLoadPortCode: string, dischargePortCode: string): void {
    this.schedulesService.getScheduleListDetail(scheduleHaderCode, carrierCode, scheduleLoadPortCode, dischargePortCode).subscribe(data =>
    {
      this.schedule = data;
      this.scheduleLineNumber = this.schedule.lineNumber;
    })
  }

  private getAlertsForGRRef(alertParameter: string): void {
    this.alertDataService.getAlertsForReference('Schedule', alertParameter).subscribe(data => { this.alertData = data })
    console.log(this.alertData);
  }

  protected addBooking() {
    this.bookingTransfer = {
      appBookingCode: null,
      appBookingLineNumber: null,
      scheduleCode: this.scheduleHeaderCode,
      scheduleLine: this.scheduleLineNumber,
      loadPortCode: this.scheduleLoadPortCode,
      bookingType: AddBookingEnum.newBookingSailingSchedule
    };

    this.router.navigate(['/add-booking'], { state: { data: this.bookingTransfer } });
    this.closeRightDrawer();
  }

  closeRightDrawer(){
    this.communicationService.closeRightNav();
  }
}
