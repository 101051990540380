import { Component, EventEmitter, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { FilterData } from '../models/list-model';
import { CommunicationService } from '../service/communication/communication.service';
import { ShipmentModel } from '../models/shipment/shipment-data-model';
import { ShipmentService } from '../service/shipment/shipment.service';
import { ColumnSetup } from '../models/column-setup';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { detailTabbedViewEnum, detailView } from '../models/detail-view-enum';
import { catchError, of, Subject, takeUntil } from 'rxjs';
import { ApiUserService } from '../service/user/api-user.service';
import { AlertService } from '../service/alerts/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionComponent } from '../dialog/subscription/subscription.component';
import { AlertNotificationModel } from '../models/alerts/alert-notification-model';
import { AlertSubscriptionInputModel } from '../models/alerts/alert-subscription-input-model';
import { AlertSubscriptionModel } from '../models/alerts/alert-subscription-model';
import { AppComponent } from '../app.component';
import { Alert } from '../models/alerts/alerts';
import { DocumentService } from '../service/documents/document.service';
import { DocumentStatus } from '../enums/document-status.enum';

@Component({
  selector: 'app-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.css'],
})
export class ShipmentListComponent implements OnInit {
  @Output() openDetailDrawer = new EventEmitter<string>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<ShipmentModel>;

  private destroy$ = new Subject<void>();
  shipmentData: ShipmentModel[] = [];
  filter!: FilterData;
  detailView = detailView;
  detailTabbedViewEnum = detailTabbedViewEnum;
  displayedColumns: ColumnSetup[] = [];
  isCustUser: boolean = false;
  extractedColumns: string[] = [];
  filterId: number = 0;
  startDate?: Date = new Date();
  endDate?: Date = new Date();
  templates: AlertSubscriptionModel[] = [];
  _snackBar: any;
  horizontalPosition: any;
  verticalPosition: any;
  shipmentDataSource = new MatTableDataSource<ShipmentModel>(); // Ensure this is declared
  constructor(
    private alertDataService: AlertService,
    private shipmentDataService: ShipmentService,
    private dialog: MatDialog,
    private communicationService: CommunicationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private appComponent: AppComponent,
    private cdr: ChangeDetectorRef,
    private apiUserService: ApiUserService,
    private documentService: DocumentService
  ) {
    this.matIconRegistry.addSvgIcon(
      'Subscribe',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/Subscribe.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewDocuments',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewDocuments.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewAlerts',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewAlerts.svg')
    );
  }

  ngOnInit(): void {
    this.shipmentServiceSetup();
    this.detectCustUser();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private configureColumns(): void {
    this.displayedColumns = [
      { name: 'shipmentStatusColor', header: '', type: 'string', visible: true },
      { name: 'customerCode', header: 'Customer', type: 'string', visible: !this.isCustUser },
      { name: 'grReference', header: 'GR Ref No', type: 'string', visible: true },
      { name: 'indicators', header: 'Indicators', type: 'string', visible: true },
      { name: 'containerNumber', header: 'Container No', type: 'string', visible: true },
      { name: 'consignee', header: 'Consignee', type: 'string', visible: true },
      { name: 'etd', header: 'ETD', type: 'string', visible: true },
      { name: 'eta', header: 'ETA', type: 'string', visible: true },
      { name: 'actions', header: 'Actions', type: 'string', visible: true }
    ];
    this.extractedColumns = this.displayedColumns.map(col => col.name);
  }

  private detectCustUser(): void {
    this.apiUserService.userInfo
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (_) => {
          this.isCustUser = this.apiUserService.IsCustUser;
        },
        error: (error) => {
          console.error('Error fetching user info:', error);
        }
      });
  }

  private shipmentServiceSetup(): void {
    this.shipmentDataService.statusFilterToggle$
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => this.applyFilter(value || ''));

    this.shipmentDataService.sendFilterTransfer$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (t) => {
          this.filter = t.filter;
          this.filterId = t.filterId;
          this.startDate = t.startDate;
          this.endDate = t.endDate;
          this.updateData(this.filterId, this.filter, this.startDate, this.endDate);
        },
        error: (error) => {
          console.error('Send FilterModel Error:', error);
          window.alert('Send FilterModel Error. Please try again later.');
        }
      });
  }

  //private checkAlertsForRow(row: any): void {
  //  const notificationModel = this.createNotificationModel(row);

  //  this.alertDataService.getAlertSubscriptionTemplates(notificationModel)
  //    .pipe(
  //      catchError(error => {
  //        console.error('Error fetching subscription templates:', error);
  //        return of([]);
  //      }),
  //      takeUntil(this.destroy$)
  //    )
  //    .subscribe((subscriptionTemplates: AlertSubscriptionModel[]) => {
  //      const hasSubscribedTemplates = subscriptionTemplates.some(template => template.isSubscribed);
  //      this.triggerCDR(); // Trigger change detection to update the UI
  //    });
  //}

  private triggerCDR() {
    this.cdr.detectChanges();
  }
 
  private createNotificationModel(row: any): AlertNotificationModel {
    return {
      AlertCode: '',
      CustomerCode: row.customerCode,
      GRReference: String(row.grReference),
      ContainerNo: row.containerNumber,
      TemplateGroup: 'Shipment'
    } as AlertNotificationModel;
  }

  getBackgroundColor(cellValue: string): string {
    switch (cellValue) {
      case 'Completed':
        return '#36DE6F';
      default:
        return '#368EDE';
    }
  }

  isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

  updateData(filterId: number, filterData: FilterData, startDate?: Date, endDate?: Date): void {
    this.shipmentDataService.getShipments(filterId, filterData, startDate, endDate).subscribe(data => {
      this.configureColumns();
      this.shipmentDataSource.sort = this.sort;
      this.shipmentDataSource.paginator = this.paginator;
      this.shipmentDataSource.data = data;
    });
  }

  viewDetails(row: any, view: detailView, tab: detailTabbedViewEnum): void {
    this.communicationService.toggleDetailView(view, tab);
    this.communicationService.getDetailViewParameter(row.grReference);
    this.openDetailDrawer.emit();

    const DETAIL_DRAWER_DELAY_MS = 500;

    // After the detail drawer closes, recheck for data changes
    setTimeout(() => {
      this.triggerCDR();
      }, DETAIL_DRAWER_DELAY_MS); // slight delay to ensure the drawer fully closes
    
  }

  public fetchDocumentStatusClass(incomingDocumentStatus: DocumentStatus): string {

    switch (incomingDocumentStatus) {
      case DocumentStatus.Rejected:
        return 'indicator-btn-documents-rejected';
      case DocumentStatus.AwaitingApproval:
        return 'indicator-btn-documents-awaiting';
      case DocumentStatus.Approved:
        return 'indicator-btn-documents-approved';
      default:
        return 'indicator-btn'; // Default class if no specific status is found
    }
  }

  subscribeToAlert(row: ShipmentModel): void {
    const notificationModel = this.createNotificationModel(row);

    this.showLoading(true);

    this.alertDataService.getAlertSubscriptionTemplates(notificationModel)
      .pipe(
        catchError(error => {
          console.error('Error fetching subscription templates:', error);
          return of([]);
        })
      )
      .subscribe((subscriptionTemplates: AlertSubscriptionModel[]) => {
        this.showLoading(false);

        const subscriptionInput = new AlertSubscriptionInputModel();
        subscriptionInput.notificationModel = notificationModel;
        subscriptionInput.templates = subscriptionTemplates;

        const dialogRef = this.dialog.open(SubscriptionComponent, {
          data: { subscriptionList: subscriptionTemplates }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            subscriptionInput.templates = result;

            this.alertDataService.updateAlertSubscription(subscriptionInput)
              .pipe(
                catchError(error => {
                  console.error('Error updating subscriptions:', error);
                  return of(null);
                })
              )
              .subscribe(response => {
                if (response) {
                  this.updateData(this.filterId, this.filter, this.startDate, this.endDate);
                }
              });
          }
        });
      });
  }

  showLoading(show: boolean): void {
    this.appComponent.showLoading(show);
  }

  openSnackBar(message: string): void {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  getExtractedColumns(columns: ColumnSetup[]): string[] {
    return columns.map(col => col.name);
  }

  applyFilter(filterValue: string): void {
    if (!filterValue) {
      this.shipmentDataSource.filter = '';
    } else {
      this.shipmentDataSource.filter = filterValue.trim().toLowerCase();
    }
  }
}
