<div class="container">
  <div class="title-wrapper">
    <div class="saved-view-wrap">
      <h1>Dashboard</h1>
    </div>
    <div class="btn-header">
      <button mat-button class="add-booking-btn" (click)="openAddWidgetForm()">
        <img src="/assets/PlusCircle.svg" alt="" class="icon">
        Add Widget
      </button>
      <button mat-button class="add-booking-btn" (click)="exportGridsterToJSON()">
        Export Gridster
      </button>
      <button mat-button class="manage-dashboard-btn" (click)="toggleEditMode()">
        {{ editMode ? 'Save' : 'Edit' }} Dashboard
      </button>
    </div>
  </div>

  <div class="modal" *ngIf="showAddWidgetForm">
    <div class="modal-content">
      <h3>Add a new widget</h3>
      <div *ngIf="widgetTypeSelection">
        <label>Select Widget Type:</label>
        <button (click)="selectWidgetType('line')">Line Chart</button>
        <button (click)="selectWidgetType('bar')">Bar Chart</button>
        <button (click)="selectWidgetType('pie')">Pie Chart</button>
        <button (click)="selectWidgetType('cards')">Cards</button>
        <button (click)="selectWidgetType('progress')">Progress</button> <!-- New widget type -->
      </div>

      <div *ngIf="!widgetTypeSelection">
        <form (ngSubmit)="addWidget()">
          <div *ngIf="selectedWidgetType === 'line' || selectedWidgetType === 'bar' || selectedWidgetType === 'pie'">
            <label>Labels (comma-separated):</label>
            <input type="text" [(ngModel)]="newWidget.labels" name="labels" />

            <label>Data Points (comma-separated):</label>
            <input type="text" [(ngModel)]="newWidget.data" name="data" />

            <label>Title:</label>
            <input type="text" [(ngModel)]="newWidget.title" name="title" />
          </div>

          <div *ngIf="selectedWidgetType === 'cards'">
            <label>Card Title:</label>
            <input type="text" [(ngModel)]="newWidget.cardTitle" name="cardTitle" />

            <label>Card Data (format: Title,Field 1,Field 2,Field 3; Title,Field 1,Field 2,Field 3):</label>
            <textarea [(ngModel)]="newWidget.cardData" name="cardData"></textarea>
          </div>

          <div *ngIf="selectedWidgetType === 'progress'">
            <label>Progress Value:</label>
            <input type="number" [(ngModel)]="newWidget.progressValue" name="progressValue" />

            <label>Progress Mode:</label>
            <select [(ngModel)]="newWidget.progressMode" name="progressMode">
              <option value="determinate">Determinate</option>
              <option value="indeterminate">Indeterminate</option>
              <option value="buffer">Buffer</option>
              <option value="query">Query</option>
            </select>
          </div>

          <button type="submit">Add Widget</button>
          <button type="button" (click)="closeAddWidgetForm()">Cancel</button>
        </form>
      </div>
    </div>
  </div>

  <div #gridsterContainer>
    <gridster [options]="gridOptions">
      <gridster-item *ngFor="let item of dashboard" [item]="item">
        <div *ngIf="item['type'] === 'line' || item['type'] === 'bar' || item['type'] === 'pie'">
          <div echarts [options]="item['chartOptions']" class="echart"></div>
          <button *ngIf="editMode" class="edit-btn" (click)="enterEditMode(dashboard.indexOf(item))">Edit</button>
        </div>
        <div *ngIf="item['type'] === 'cards'" class="widget-list-container">
          <h3>{{ item['cardTitle'] }}</h3>
          <div class="card-container">
            <mat-card appearance="outlined" *ngFor="let card of item['cards']">
              <mat-card-content class="list-container">
                <div class="col-md-12">{{ card['field1'] }}</div>
                <div class="col-md-12"><strong>{{ card['field2'] }} | {{ card['field3'] }}</strong></div>
</mat-card-content>
            </mat-card>
          </div>
          <button *ngIf="editMode" class="edit-btn" (click)="enterEditMode(dashboard.indexOf(item))">Edit</button>
        </div>
        <div *ngIf="item['type'] === 'progress'" style="padding: 20px; display: flex; row-gap: 16px; flex-direction: column; flex:1">
          <div class="card-container" style="display: flex; row-gap: 12px; flex-direction: row; justify-content:center">
            <mat-progress-spinner class="example-margin"
                                  [mode]="item['progressMode']"
                                  [value]="item['progressValue']">
            </mat-progress-spinner>
          </div>
          <div class="card-text-row">{{ item['cardTitle'] }}</div>
          <button *ngIf="editMode" class="edit-btn" (click)="enterEditMode(dashboard.indexOf(item))">Edit</button>
        </div>
      </gridster-item>
    </gridster>
  </div>
</div>
