import {Component, ViewChild, ChangeDetectorRef, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import {MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SchedulesService } from '../service/schedules/schedules.service';
import { IScheduleListModel, ScheduleListModel } from '../models/schedules/schedule-list-model';
import { FormControl, Validators} from '@angular/forms';
import { CommunicationService } from '../service/communication/communication.service';
import { FilterData } from '../models/list-model';
import { FilterEnum } from '../models/filter-enum';
import { ColumnSetup } from '../models/column-setup';
import { detailTabbedViewEnum, detailView } from '../models/detail-view-enum';
import { of, Subject } from 'rxjs';
import { DataFilter, FilterTransferModel } from '../models/filter-models';
import { takeUntil, catchError, distinctUntilChanged, skip } from 'rxjs/operators';
import { ApiUserService } from '../service/user/api-user.service';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertNotificationModel } from '../models/alerts/alert-notification-model';
import { AlertSubscriptionModel } from '../models/alerts/alert-subscription-model';
import { AlertSubscriptionInputModel } from '../models/alerts/alert-subscription-input-model';
import { SubscriptionComponent } from '../dialog/subscription/subscription.component';
import { AlertService } from '../service/alerts/alert.service';
import { AppComponent } from '../app.component';
import { KeycloakService } from 'keycloak-angular';
import { Alert } from '../models/alerts/alerts';

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrl: './schedules.component.css'
})
export class SchedulesComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  @ViewChild(MatPaginator) paginator = {} as MatPaginator ;
  @ViewChild(MatSort) sort = {} as MatSort;
  protected scheduleDataSource = new MatTableDataSource<ScheduleListModel>();

  @Output() openFilter = new EventEmitter<string>();
  protected filter!: FilterData;
  protected filterParameter!: FilterData;
  protected savedFilters: DataFilter[] = [];
  protected currentFilter: DataFilter = new DataFilter();
  protected filterData: FilterData = {};
  protected isCustUser: boolean = false;

  protected FilterEnum = FilterEnum;
  protected selectedButton = "";
  protected detailTabbedViewEnum = detailTabbedViewEnum;

  protected detailView = detailView;

  protected displayedColumns: ColumnSetup[] = [];
  protected extractedColumns: string[] = [];

  protected scheduleList: IScheduleListModel[] = []

  protected dateFrom = new FormControl(new Date());
  protected dateTo = new FormControl({
    value: null,
    disabled: false
  });

  protected filterList = new FormControl();

  protected _snackBar: any;
  protected horizontalPosition: any;
  protected verticalPosition: any;
  protected subscribedTemplatesMap = new Map<string, boolean>();
  protected hasUnreadAlert: boolean = false;

  
  protected yesterday = new Date();
  protected setDate = new Date();

  constructor(
    private schedulesService: SchedulesService,
    private cdr: ChangeDetectorRef,
    private communicationService: CommunicationService,
    private dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private alertDataService: AlertService,
    private appComponent: AppComponent,
    private apiUserService: ApiUserService
  ) {
    this.matIconRegistry.addSvgIcon(
      'Subscribe',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/Subscribe.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewDocuments',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewDocuments.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewAlerts',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewAlerts.svg')
    )

    // this.yesterday.setDate(this.yesterday.getDate() - 0);
   }

  ngOnInit(): void {

    this.schedulesService.filterData$.subscribe((value) => {
      this.filter = value;

      this.loadSavedFilters();
      this.updateData(this.filter);
    });

    this.schedulesService.sendActiveFilterToList$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (filterReceived) => {
          this.updateCurrentFilter(filterReceived.id, filterReceived.filterName, filterReceived.filter)
          this.updateData(this.currentFilter.filter);
        }
      });

    this.schedulesService.filterNameSaved$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (t) => {
          this.loadSavedFilters();
          this.updateCurrentFilter(0, t, {});
        },
        error: (error) => {
          console.error('Error handling filter name saved event', error);
          window.alert('Failed to handle filter name saved event. Please try again later.');
        }
      });

      console.log('Subscribing to dateFrom valueChanges');
      this.dateFrom.valueChanges.subscribe(startDate => {
        console.log('dateFrom changed:', startDate);
        if (startDate) {
         this.setDate = startDate;
        } else {
          this.dateTo.clearValidators();
        }
        this.dateTo.updateValueAndValidity();
      });

      this.detectCustUser();    
  }

  configureColumns(): void {
    this.displayedColumns = [
      { name: 'carrier', header: 'Carrier', type: 'string', visible: true },
      { name: 'vesselName', header: 'Vessel Name', type: 'string', visible: true },
      { name: 'voyageNo', header: 'Voyage No', type: 'string', visible: true },
      { name: 'stackStatus', header: 'Stack Status', type: 'string', visible: true },
      { name: 'stackEnd', header: 'Stack End', type: 'date', visible: true },
      { name: 'marketCode', header: 'Market', type: 'string', visible: true },
      { name: 'portCountry', header: 'Country', type: 'string', visible: true },
      { name: 'loadPort', header: 'Load Port', type: 'string', visible: true },
      { name: 'loadETD', header: 'Load ETD', type: 'date', visible: true },
      { name: 'dischargePort', header: 'Discharge Port', type: 'string', visible: true },
      { name: 'dischargeETA', header: 'Discharge ETA', type: 'date', visible: true },
      { name: 'serviceName', header: 'Service Name', type: 'string', visible: true },
      { name: 'siCutoffDateTime', header: 'SI Cut-off', type: 'date', visible: true },
      // { name: 'indicators', header: 'Indicators', type: 'string', visible: true },
      { name: 'actions', header: 'Action', type: 'string', visible: true },
    ]
    this.extractedColumns = this.displayedColumns.map(col => col.name);
  }

  applyFilterList(filter: string) {
    const filterGroups = filter.split('|').map(group => group.split('&').map(value => value.trim().toLowerCase()));

    this.scheduleDataSource.filterPredicate = (data: any, filter: string) => {
      const dataStr = JSON.stringify(data).toLowerCase();
      return filterGroups.some(group => group.every(value => dataStr.includes(value)));
    };

    this.scheduleDataSource.filter = filter; // Trigger the filter
  }

  private detectCustUser(): void {
    this.apiUserService.userInfo
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (_) => {
          this.isCustUser = this.apiUserService.IsCustUser;
        },
        error: (error) => {
          console.error('Error fetching user info:', error);
        }
      });
  }

  private updateData(filterData?: FilterData): void {

    const dateFromValue = this.dateFrom.value ? new Date(this.dateFrom.value) : undefined; 
    const dateToValue = this.dateTo.value ? new Date(this.dateTo.value) : undefined; 

    this.schedulesService.getScheduleList(this.currentFilter.id, this.currentFilter.filter, dateFromValue, dateToValue).subscribe(data => {
      this.configureColumns();
      this.scheduleDataSource.data = data;
      this.scheduleDataSource.sort = this.sort;
      this.scheduleDataSource.paginator = this.paginator;
    });

    this.filterParameter = {};
  }

  onOpenFilter(openFiler: FilterEnum) {
    this.communicationService.toggleFilter(openFiler);
    this.sendingActiveFilter();
  }

  loadSavedFilters() {
    this.schedulesService.getFilters()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          this.savedFilters = data;
          if (this.currentFilter.filterName !== "") {
            let filterId = this.getFilterIdByName(this.currentFilter.filterName);
            this.updateCurrentFilter(filterId, this.currentFilter.filterName, this.getSavedFilterFromId(filterId));
            this.updateData();
          }
        }
      });
  }

  processFilterData(key: string, value: string) {
    const dataArray = value
      .split(',')
      .filter(item => item.trim() !== '');
    this.filterParameter[key] = dataArray;
  }

  setupFilter(column: string) {
    this.scheduleDataSource.filterPredicate = (d: any, filter: string) => {
      const textToSearch = d[column] && d[column].toLowerCase() || '';
      return textToSearch.indexOf(filter) !== -1;
    };
  }

  applyFilter(filterId: number): void {
    this.filterData = {};

    if (this.currentFilter.id === filterId) {
      this.updateCurrentFilter(0, '', {});
    }
    else {
      this.updateCurrentFilter(filterId, this.getSavedFilterNameFromId(filterId), this.getSavedFilterFromId(filterId))
    }
    this.updateData(this.currentFilter.filter)
  }

  updateCurrentFilter(id: number, name: string, filter: FilterData) {
    this.currentFilter.id = id;
    this.currentFilter.filterName = name;
    this.currentFilter.filter = filter;
  }

  getSavedFilterNameFromId(filterId: number): string {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filterName : '';
  }

  getSavedFilterFromId(filterId: number): FilterData {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filter : {};
  }

  getFilterIdByName(filterName: string): number {
    const filter = this.savedFilters.find(f => f.filterName === filterName);
    return filter ? filter.id : 0;
  }

  sendingActiveFilter() {
    this.schedulesService.sendingActiveFilterToFilter(this.currentFilter)
  }

  subscribeToAlert(row: any): void {
    const notificationModel = this.createNotificationModel(row);
 
    this.showLoading(true);

    this.alertDataService.getAlertSubscriptionTemplates(notificationModel)
      .pipe(
        catchError(error => {
          console.error('Error fetching subscription templates:', error);
          return of([]);
        })
      )
      .subscribe((subscriptionTemplates: AlertSubscriptionModel[]) => {
     
        this.showLoading(false);

        const subscriptionInput = new AlertSubscriptionInputModel();
        subscriptionInput.notificationModel = notificationModel;
        subscriptionInput.templates = subscriptionTemplates;

        const dialogRef = this.dialog.open(SubscriptionComponent, {
          data: { subscriptionList: subscriptionTemplates } 
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            subscriptionInput.templates = result;

            this.alertDataService.updateAlertSubscription(subscriptionInput)
              .pipe(
                catchError(error => {
                  console.error('Error updating subscriptions:', error);
                  return of(null);
                })
              )
              .subscribe(response => {
                if (response) {
                  this.updateData(this.filter);
                }
              });
          }
        });
      });
  }
  
  showLoading(show: boolean): void {
    this.appComponent.showLoading(show);
  }

  private createNotificationModel(row: any): AlertNotificationModel {
    return {
      AlertCode: '',
      ScheduleCode: row.sailingScheduleHeaderCode,
      TemplateGroup: 'Schedule'
    } as AlertNotificationModel;
  }

  public openSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  viewDetails(row: any, detailViewToOpen: detailView,tab: detailTabbedViewEnum): void {
    var scheduleParameter = row['sailingScheduleHeaderCode'] + '|' + row['carrierCode'] + '|' + row['loadPortCode'] + '|' + row['dischargePortCode'];

    this.communicationService.toggleDetailView(detailViewToOpen,tab);
    this.communicationService.getDetailViewParameter(scheduleParameter)
  }

  protected isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

  protected getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  protected getExtractedColumns(columns: ColumnSetup[]) {
    return columns.map(col => col.name);

  }

  protected runReportClick(): void {
    // Replace with your desired logic (e.g., open a dialog, navigate, etc.)
    // console.log('Run Report Button clicked selectedCountry = ', this.dateTo.value );
    //this.applyParameters()

    this.updateData(this.filter);
  }

  handleButtonClick(row: any): void {
    // Replace with your desired logic (e.g., open a dialog, navigate, etc.)
    console.log('Button clicked for row:', row);
    alert(row['voyageNo'])
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

