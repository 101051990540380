import { Component, ViewChild, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { SidenavComponent } from './sidenav/sidenav.component';
import { environment } from '../environments/environment';
import { Alert } from './models/alerts/alerts';
import { detailTabbedViewEnum, detailView } from './models/detail-view-enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'goglobal.root2go.ui.frontend';
  worker: Worker | undefined;
  socketSubscribed: boolean = false;
  alertModel: Alert = new Alert(0, '', '', '', '', '', '', new Date(), '', false, detailView.none, detailTabbedViewEnum.none, '');
  wsMessage = new Subject<Alert>();
  isLoading = false;

  @ViewChild(SidenavComponent, { static: false }) sidenavComponent!: SidenavComponent;

  // Inject ChangeDetectorRef into the constructor
  constructor(
    private breakpointObserver: BreakpointObserver,
    private cd: ChangeDetectorRef // Inject ChangeDetectorRef
  ) { }

  showLoading(show: boolean): void {
    this.isLoading = show;
  }

  ngOnInit() {
    // Ensure ChangeDetectorRef is not undefined before using it
    if (this.cd) {
      this.cd.detectChanges();
    }

    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(new URL('./workers/alert-worker.worker', import.meta.url), { type: 'module' });

      // Listen for messages from the worker
      this.worker.onmessage = ({ data }) => {
        console.log('Message from worker:', data);
        const item: any = JSON.parse(data);
        const detailsView = detailView[item.DetailsView.toString() as keyof typeof detailView];
        const detailsTab = detailTabbedViewEnum[item.DetailsTab.toString() as keyof typeof detailTabbedViewEnum];
        const alertModel = new Alert(
          item.Id,
          item.UserId,
          item.CustomerCode,
          item.Customer,
          item.Title,
          item.TemplateGroup,
          item.Code,
          item.AlertDate,
          item.Notification,
          item.IsRead,
          detailsView,
          detailsTab,
          item.Reference
        );
        this.wsMessage.next(alertModel);
      };
    } else {
      console.log('Web Workers are not supported in this environment.');
    }
  }

  ngOnDestroy() {
    if (this.worker) {
      this.worker.terminate();
    }
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  subscribeNotifications(userId: string) {
    if (this.worker !== undefined && this.socketSubscribed !== true) {
      this.worker.postMessage({ socketUrl: environment.socketUrl, userId });
    }
    // Ensure ChangeDetectorRef is not undefined before using it
    if (this.cd) {
      this.cd.detectChanges();
    }
  }

  onMenuOpenerClick() {
    this.sidenavComponent.toggleDrawer();
  }

  onOpenAlerts() {
    this.sidenavComponent.openAlertDrawer();
    // Ensure ChangeDetectorRef is not undefined before using it
    if (this.cd) {
      this.cd.detectChanges();
    }
  }
}
