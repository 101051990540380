import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CompactType, DisplayGrid, GridType, GridsterConfig, GridsterItem } from 'angular-gridster2';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @ViewChild('gridsterContainer', { static: false }) gridsterContainer!: ElementRef;

  gridOptions: GridsterConfig = {
    gridType: GridType.Fit,
    displayGrid: DisplayGrid.OnDragAndResize,
    compactType: CompactType.CompactLeftAndUp,
    margin: 3,
    draggable: {
      enabled: true
    },
    resizable: {
      enabled: true
    },
    minCols: 3,
    maxCols: 34,
    minRows: 5,
    maxRows: 5,
    maxItemCols: 3,
    minItemCols: 1,
    maxItemRows: 3,
    minItemRows: 1,
    maxItemArea: 2500,
    minItemArea: 1,
    defaultItemCols: 1,
    defaultItemRows: 1
  };

  dashboard: GridsterItem[] = [];
  showAddWidgetForm: boolean = false;
  widgetTypeSelection: boolean = true;
  selectedWidgetType: string = '';
  editMode: boolean = false;

  newWidget = {
    chartType: 'line',
    labels: '',
    data: '',
    title: '',
    cardTitle: '',
    cardData: '',
    progressValue: 0,
    progressMode: 'determinate'  // Default progress mode
  };

  dummyData = {
    line: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
      data: [120, 200, 150, 80, 70]
    },
    bar: {
      labels: ['A', 'B', 'C', 'D', 'E'],
      data: [5, 10, 15, 20, 25]
    },
    pie: {
      labels: ['Red', 'Blue', 'Green', 'Yellow'],
      data: [30, 20, 15, 35]
    },
    cards: [
      { title: 'Card 1', field1: 'GR Ref.', field2: 'Vessel Name', field3: 'Container' },
      { title: 'Card 2', field1: 'Field 1B', field2: 'Field 2B', field3: 'Field 3B' },
      { title: 'Card 3', field1: 'Field 1C', field2: 'Field 2C', field3: 'Field 3C' }
    ],
    progress: {
      value: 50,
      mode: 'determinate'
    }
  };

  ngOnInit() {
    this.addDummyWidgets();
  }

  addDummyWidgets() {
    this.addProgress(this.dummyData.progress.value, this.dummyData.progress.mode, 'Completed shipments in the last 24hrs');
    this.addProgress(this.dummyData.progress.value, this.dummyData.progress.mode, 'Shipment departures from origin ports in last 24hrs');
    this.addProgress(this.dummyData.progress.value, this.dummyData.progress.mode, 'Containers in trans-shipment ports');
    this.addChart('line', this.dummyData.line.labels, this.dummyData.line.data, 'Line Chart Example');
    this.addChart('bar', this.dummyData.bar.labels, this.dummyData.bar.data, 'Bar Chart Example');
    this.addChart('pie', this.dummyData.pie.labels, this.dummyData.pie.data, 'Pie Chart Example');
    this.addCards('Documents awaiting approval', this.dummyData.cards);
  }

  addChart(type: string, labels: string[], data: number[], title: string) {
    const item: GridsterItem = {
      cols: 2,
      rows: 2,
      y: 0,
      x: 0,
      type,
      chartOptions: {
        xAxis: {
          type: 'category',
          data: labels
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data,
          type
        }]
      },
      title
    };
    this.dashboard.push(item);
  }

  addCards(cardTitle: string, cardData: { title: string, field1: string, field2: string, field3: string }[]) {
    const item: GridsterItem = {
      cols: 2,
      rows: 2,
      y: 0,
      x: 0,
      type: 'cards',
      cardTitle,
      cards: cardData
    };
    this.dashboard.push(item);
  }

  addProgress(value: number, mode: string, title: string) {
    const item: GridsterItem = {
      cols: 2,
      rows: 2,
      y: 0,
      x: 0,
      type: 'progress',
      progressValue: value,
      progressMode: mode,
      cardTitle: title
    };
    this.dashboard.push(item);
  }

  openAddWidgetForm() {
    this.showAddWidgetForm = true;
    this.widgetTypeSelection = true;
  }

  closeAddWidgetForm() {
    this.showAddWidgetForm = false;
    this.widgetTypeSelection = true;
  }

  selectWidgetType(type: string) {
    this.selectedWidgetType = type;
    this.widgetTypeSelection = false;
  }

  addWidget() {
    switch (this.selectedWidgetType) {
      case 'line':
      case 'bar':
      case 'pie':
        this.addChart(
          this.selectedWidgetType,
          this.newWidget.labels.split(','),
          this.newWidget.data.split(',').map(val => +val),
          this.newWidget.title
        );
        break;
      case 'cards':
        this.addCards(this.newWidget.cardTitle, this.newWidget.cardData.split(';').map(entry => {
          const [title, field1, field2, field3] = entry.split(',');
          return { title, field1, field2, field3 };
        }));
        break;
      case 'progress':
        this.addProgress(this.newWidget.progressValue, this.newWidget.progressMode, this.newWidget.cardTitle);
        break;
    }
    this.newWidget = {
      chartType: 'line',
      labels: '',
      data: '',
      title: '',
      cardTitle: '',
      cardData: '',
      progressValue: 0,
      progressMode: 'determinate'
    };
    this.closeAddWidgetForm();
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
    if (this.gridOptions) {
      this.gridOptions.draggable!.enabled = this.editMode;
      this.gridOptions.resizable!.enabled = this.editMode;
    }
  }

  enterEditMode(index: number) {
    const item = this.dashboard[index];
    if (item['type'] === 'progress') {
      this.newWidget.progressValue = item['progressValue'];
      this.newWidget.progressMode = item['progressMode'];
      this.newWidget.cardTitle = item['cardTitle'];
    } else if (item['type'] === 'cards') {
      this.newWidget.cardTitle = item['cardTitle'];
      this.newWidget.cardData = item['cards'].map((card: { title: string; field1: string; field2: string; field3: string }) =>
        `${card.title},${card.field1},${card.field2},${card.field3}`
      ).join(';');
    } else {
      // handle other types if needed
    }
    this.openAddWidgetForm();
    this.selectedWidgetType = item['type'];
  }

  exportGridsterToJSON() {
    const json = JSON.stringify(this.dashboard, null, 2);
    console.log(json);
  }
}
