import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FilterData } from '../models/list-model';
import { CommunicationService } from '../service/communication/communication.service';
import { FilterEnum } from '../models/filter-enum';
import { ShipmentService } from '../service/shipment/shipment.service';
import { skip, Subject, takeUntil } from 'rxjs';
import { DataFilter, FilterTransferModel } from '../models/filter-models';



@Component({
  selector: 'app-shipments',
  templateUrl: './shipments.component.html',
  styleUrls: ['./shipments.component.css']
})

export class ShipmentsComponent implements OnInit, OnDestroy  {

  private destroy$ = new Subject<void>();
  selectedButton="";
  dateFrom = new FormControl();
  dateTo = new FormControl();
  query: string[][] = [];
  filterData: FilterData = {};
  FilterEnum = FilterEnum; 
  currentFilter: DataFilter = new DataFilter();
  savedFilters: DataFilter[] = [];
  selectedFilterId: number = 0;
  SavedFilterName: string = '';
  selectedType: string = 'Ocean';
  isRoadShipmentDisabled: boolean = true;
  isAirShipmentDisabled: boolean = true;



  constructor(private shipingDataService: ShipmentService,private communicationService: CommunicationService) {}


  ngOnInit(): void {
    this.loadSavedFilters();

    this.shipingDataService.filterNameSaved$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (t) => {
          this.SavedFilterName = t;
          this.filterData = {};
          this.loadSavedFilters();
        },
        error: (error) => {
          console.error('Error handling filter name saved event', error);
          window.alert('Failed to handle filter name saved event. Please try again later.');
        }
      });

    this.shipingDataService.filterData$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (value) => {
          this.filterData = value;
          this.selectedFilterId = 0;
          this.updateCurrentFilter(this.selectedFilterId, this.getSavedFilterNameFromId(this.selectedFilterId), this.filterData)
          this.shipingDataService.sendingFilterTransferModel(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value));
        },
        error: (error) => {
          console.error('Error receiveing filter data', error);
          window.alert('Error receiveing filter data');
        }
      });
  }
  onOpenFilter(filter: FilterEnum) {
    this.communicationService.toggleFilter(filter);
    this.sendingActiveFilter();
  }

  applyFilter(filterId: number): void {
    this.filterData = {};

    if (this.selectedFilterId === filterId) {
      this.selectedFilterId = 0;
      this.updateCurrentFilter(0, '', {});
    }
    else {
      this.selectedFilterId = filterId;
      this.updateCurrentFilter(this.selectedFilterId, this.getSavedFilterNameFromId(this.selectedFilterId), this.getSavedFilterFromId(this.selectedFilterId))
    }    
    this.shipingDataService.sendingFilterTransferModel(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value));
  }

  loadSavedFilters() {
    this.shipingDataService.getFilters()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          this.savedFilters = data;
          if (this.SavedFilterName !== "") {
            this.changeSelectedButton(this.SavedFilterName);
            this.selectedFilterId = this.getFilterIdByName(this.SavedFilterName);
            this.updateCurrentFilter(this.selectedFilterId, this.SavedFilterName, this.getSavedFilterFromId(this.selectedFilterId));
            this.shipingDataService.sendingFilterTransferModel(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value));
          }
        }
      });
  }

  changeSelectedButton(newSavedFilterName: string): void {
    this.selectedFilterId = this.getFilterIdByName(newSavedFilterName);
  }

  selectButton(button: string) {
    if (this.selectedButton === button) {
      this.selectedButton = '';
      this.shipingDataService.toggleStatusFilter('');
    } else {
      this.selectedButton = button;
      this.shipingDataService.toggleStatusFilter(button);
    }
    
  }

  processFilterData(key: string, value: string) {
    const dataArray = value
      .split(',')
      .filter(item => item.trim() !== '');

    this.filterData[key] = dataArray;
  }

  updateCurrentFilter(id: number, name: string, filter: FilterData) {
    this.currentFilter.id = id;
    this.currentFilter.filterName = name;
    this.currentFilter.filter = filter;
  }

  getSavedFilterNameFromId(filterId: number): string {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filterName : '';
  }

  getSavedFilterFromId(filterId: number): FilterData {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filter : {};
  }

  getFilterIdByName(filterName: string): number {
    const filter = this.savedFilters.find(f => f.filterName === filterName);
    return filter ? filter.id : 0;
  }
  
  sendingActiveFilter() {
    this.shipingDataService.sendingActiveFilterToFilter(this.currentFilter)
   }

   runReportClick(): void {
    this.shipingDataService.sendingFilterTransferModel(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value));
  }

  selectType(type: string) {
    if (type === 'Ocean') {
      this.selectedType = 'Ocean';
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}

