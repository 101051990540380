<div style="overflow-x:auto;" class="mat-elevation-z2" *ngIf="isMinWidth(760)">
  <table mat-table [dataSource]="dataSource" class="full-width-table" matSort aria-label="Elements">

    <ng-container *ngFor="let column of displayedColumns">
      <ng-container [matColumnDef]="column.name" id="test">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!column.visible">
          {{ column.header }}
        </th>
        <td mat-cell *matCellDef="let row">
          <ng-container *ngIf="column.name !== 'selected'">
            <ng-container *ngIf="column.type === 'date'">
              {{ row[column.name] | date: 'dd MMM yyyy' }}
            </ng-container>
            <ng-container *ngIf="column.type !== 'date'">
              {{ row[column.name] }}
            </ng-container>
          </ng-container>
          <ng-container *ngIf="column.name === 'selected'">
            <mat-checkbox  class="primary" [(ngModel)]="isSelected" (click)="selectRow(row)">
            </mat-checkbox>
          </ng-container>
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="getExtractedColumns(displayedColumns)"></tr>
    <tr mat-row *matRowDef="let row; columns: getExtractedColumns(displayedColumns);"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell center" style="text-align:center" [attr.colspan]="displayedColumns.length">
        <div *ngIf="isLoading; else noData"  style="display: flex; justify-content: center; align-items: center; background: white;" >
          <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
        </div>
        <ng-template #noData>
          <div style="text-align:center; padding: 16px;">
            <strong *ngIf="dataSource.data.length === 0">No data found. Please apply filters to see results.</strong>
            <strong *ngIf="dataSource.data.length > 0">No data available.</strong>
          </div>
        </ng-template>
      </td>
    </tr>
  </table>

  <mat-divider></mat-divider>

  <mat-paginator #paginator [length]="dataSource.data.length" [pageIndex]="0" [pageSize]="10"
                 [pageSizeOptions]="[5, 10, 20]" aria-label="Select page">
  </mat-paginator>
</div>

<div *ngIf="!isMinWidth(760)">
  <mat-card *ngFor="let row of dataSource.filteredData" class="card" id="mobileCard">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-checkbox (click)="selectRow(row)"></mat-checkbox>
      </div>
      <mat-card-subtitle>
        {{ getValueForRowColumn(row, 'carrier') }} - {{ getValueForRowColumn(row, 'vesselName') }}
        
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <ng-container *ngFor="let column of displayedColumns">
        <p *ngIf="column.visible">
          <strong>{{ column.header }}:</strong>
          <ng-container *ngIf="column.type === 'date'">
            {{ getValueForRowColumn(row, column.name) | date: 'dd MMM yyyy' }}
          </ng-container>
          <ng-container *ngIf="column.type !== 'date'">
            {{  getValueForRowColumn(row, column.name) }}
          </ng-container>
        </p>
      </ng-container>
    </mat-card-content>
  </mat-card>
</div>
