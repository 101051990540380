import { BookingHeaderModel } from "./bookings/booking-header-model";
import { ScheduleLinesModel } from "./schedules/schedule-lines-model";
import { ScheduleListModel } from "./schedules/schedule-list-model";

export interface IBookingLine {
  lineNo: number;
  bookingRef: string;
  clientRef: string;
  containerStatus: string;
  appBookingStatus: string;
  rejectionReason: string | null;
  dischargePort: string;
  finalDestination: string;
  confirmedPackDate: Date | null;
  commodity: string;
  loadingPoint1: string;
  loadingPoint2: string;
  temperature: number;
  quantity: number;
  lateStackRequest: boolean;
}

export interface IBookingModel {
  customerCode: string;
  grRefNo: number;
  appBookingStatus: string;
  containerStatus: string;
  commodityCode: string;
  commodity: string;
  loadPortCode: string;
  loadPort: string;
  dischargePortCode: string;
  dischargePort: string;
  temperature: number;
  carrier: string;
  carrierCode: string;
  sailDate: Date;
  marketCode: string;
  market: string;
  lateStack: boolean;
  clientName: string;
  appBookingStatusColor:string
  bookingLines: IBookingLine[];
}

export class BookingModel implements IBookingModel {
  customerCode: string = '';
  grRefNo: number = 0;
  appBookingStatus: string = '';
  containerStatus: string = '';
  commodityCode: string = '';
  commodity: string = '';
  loadPortCode: string = '';
  loadPort: string = '';
  dischargePortCode: string = '';
  dischargePort: string = '';
  temperature: number = 0;
  carrierCode: string = '';
  carrier: string = '';
  sailDate: Date = new Date();
  marketCode: string = '';
  market: string = '';
  lateStack: boolean = false;
  clientName: string = '';
  appBookingStatusColor=this.appBookingStatus;
  bookingLines: IBookingLine[] = [];
}

export class BookingLine implements IBookingLine {
  lineNo: number = 0;
  bookingRef: string = '';
  clientRef: string = '';
  containerStatus: string = '';
  appBookingStatus: string = '';
  rejectionReason: string | null = null;
  dischargePort: string = '';
  finalDestination: string = '';
  confirmedPackDate: Date | null = null;
  commodity: string = '';
  loadingPoint1: string = '';
  loadingPoint2: string = '';
  temperature: number = 0;
  quantity: number = 0;
  lateStackRequest: boolean = false;
}

export interface IAddBookingModel {
  customerCode: string,  //H Y  Check What is Client
  ServiceName: string,
  LoadPortCode: string,
  VoyageNo: string,
  VesselName: string,
  appbookingCode: string;
  lineNumber: number;
  grReferenceNumber: string;

  clientReference?: string,
  containerTypeCode: string,
  commodityCode: string,
  temperatureCode: string,
  stepUp: string,
  steri: boolean,
  vents: string,
  weight: number,
  loadingPoint1: string,
  loadingPoint2: string,
  loadingPoint3: string | null,
  confirmedPackDateTime: string,
  preVerificationKey: string | null,
  prelimConsignee: string | null,
  dischargePortCode: string,
  quantity: number,
  solasMethod: string,
  comments: string,
  temptale: boolean,
  temptaleDescription: string,
  temptaleQuantity: number,
  finalDestinationCode: string | null
}

export class AddBookingModel implements IAddBookingModel {
  //Booking Headerdata
  customerCode: string = '';
  ServiceName: string = '';
  LoadPortCode: string = '';
  VoyageNo: string = '';
  VesselName: string = '';
  appbookingCode: string = '';
  lineNumber: number = 0;

  grReferenceNumber: string = '';

  //Booking Line data
  clientReference?: string = '';
  containerTypeCode: string = '';
  commodityCode: string = '';
  temperatureCode: string = '';
  stepUp: string = '';
  steri: boolean = false;
  vents: string = '';
  weight: number = 0;
  loadingPoint1: string = '';
  loadingPoint2: string = '';
  loadingPoint3: string | null = null;
  confirmedPackDateTime: string = '';
  preVerificationKey: string | null = null;
  prelimConsignee: string | null = null;
  dischargePortCode: string = '';
  quantity: number = 0
  solasMethod: string = '';
  comments: string = '';
  temptale: boolean = false;
  temptaleDescription: string = '';
  temptaleQuantity: number = 0;
  finalDestinationCode: string | null = null;
}

export interface IBookingDataTransferModel {
  appBookingCode: string | null;
  appBookingLineNumber: number | null;
  scheduleCode: string | null;
  scheduleLine: number | null;
  loadPortCode: string | null;
  bookingType: AddBookingEnum
}

export class BookingDataTransferModel implements IBookingDataTransferModel{
  appBookingCode: string | null = null;
  appBookingLineNumber: number | null = null;
  scheduleCode: string | null = null;
  scheduleLine: number | null = null;
  loadPortCode: string | null = null;
  bookingType: AddBookingEnum = AddBookingEnum.none;
}

export enum AddBookingEnum {
  none,
  newBooking,
  newBookingSailingSchedule,
  editBookingLine,
  addBookingLine,
  copyBookingLine
}
