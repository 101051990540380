<!--<form class="example-form">-->
<div class="row">
  <div class="col-md-12">
    <ng-container *ngIf="!isCustUser">
      <mat-form-field *ngIf="customers">
        <mat-label>Select Clients</mat-label>
        <mat-select [formControl]="customers">
          <mat-option>
            <ngx-mat-select-search [formControl]="customersMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Customer not found"></ngx-mat-select-search>
          </mat-option>

          <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer.code">
            {{customer.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
  <div class="col-md-12">
    <ng-container>
      <mat-form-field *ngIf="dischargePorts">
        <mat-label>Select Discharge Port</mat-label>
        <mat-select [formControl]="dischargePorts">
          <mat-option>
            <ngx-mat-select-search [formControl]="dischargePortsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Discharge Port not found"></ngx-mat-select-search>
          </mat-option>

          <mat-option *ngFor="let dischargePort of filteredDischargePorts | async" [value]="dischargePort.code">
            {{dischargePort.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
  <div class="col-md-12">
    <ng-container>
      <mat-form-field *ngIf="finalDestinations">
        <mat-label>Select Final Destination</mat-label>
        <mat-select [formControl]="finalDestinations">
          <mat-option>
            <ngx-mat-select-search [formControl]="finalDestinationsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Final Destination not found"></ngx-mat-select-search>
          </mat-option>

          <mat-option *ngFor="let finalDestination of filteredFinalDestinations | async" [value]="finalDestination.code">
            {{finalDestination.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
  <div class="col-md-3">
    <mat-form-field style="width:30%">
      <mat-label>Commodity</mat-label>
      <mat-select [formControl]="commodities">
        <mat-option>
          <ngx-mat-select-search [formControl]="commoditiesMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Commodity Not Found"></ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor="let commodity of filteredCommodities | async" [value]="commodity.code">
          {{commodity.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-3">
    <mat-form-field style="width:30%">
      <mat-label>Weight</mat-label>
      <input matInput type="number" [formControl]="weight">
    </mat-form-field>

  </div>
  <div class="col-md-3">
    <mat-form-field style="width:30%">
      <mat-label>Temperature</mat-label>
      <mat-select [formControl]="temperatures">
        <mat-option>
          <ngx-mat-select-search [formControl]="temperaturesMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Temperature Not Found"></ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor="let temperature of filteredTemperatures | async" [value]="temperature.code">
          {{temperature.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-md-3">
    <mat-form-field class="col-xs-12">
      <mat-label>Vents</mat-label>
      <mat-select [formControl]="vents">
        <mat-option>
          <ngx-mat-select-search [formControl]="ventsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Vents Not Found"></ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor="let vent of filteredVents | async" [value]="vent.code">
          {{vent.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-6">
    <mat-form-field>
      <mat-label>Client Ref</mat-label>
      <input matInput [formControl]="clientRefNo">
    </mat-form-field>
  </div>
<div class="col-md-3">
  <mat-form-field>
    <mat-label>Load date</mat-label>
    <input matInput [matDatepicker]="dateToPicker" placeholder="Choose a date" [formControl]="loadDateTime" />
    <mat-datepicker-toggle matIconSuffix [for]="dateToPicker"></mat-datepicker-toggle>
    <mat-datepicker #dateToPicker>
    </mat-datepicker>
  </mat-form-field>
</div>
<div class="col-md-3">
  <mat-form-field>
    <mat-label>Load time</mat-label>
    <input matInput placeholder="HH:MM or HHMM" [formControl]="loadTime" (blur)="formatTime()" />
    <mat-error *ngIf="loadTime.hasError('invalidTime')">
      Please enter a valid time in HH:MM or HHMM format.
    </mat-error>
  </mat-form-field>
</div>
  <div class="col-md-3">
    <mat-form-field class="col-xs-12">
      <mat-label>Loading point 1</mat-label>
      <mat-select [formControl]="loadpoint1">
        <mat-option>
          <ngx-mat-select-search [formControl]="loadpointsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Load Point Not Found"></ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor="let loadpoint of filteredLoadpoints | async" [value]="loadpoint.code">
          {{loadpoint.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-3">
    <mat-form-field class="col-xs-12">
      <mat-label>Loading point 2</mat-label>
      <mat-select [formControl]="loadpoint2">
        <mat-option>
          <ngx-mat-select-search [formControl]="loadpointsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Load Point Not Found"></ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor="let loadpoint of filteredLoadpoints | async" [value]="loadpoint.code">
          {{loadpoint.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-3">
    <mat-form-field class="col-xs-12">
      <mat-label>Container type</mat-label>
      <mat-select [formControl]="containerTypes">
        <mat-option>
          <ngx-mat-select-search [formControl]="containerTypesMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Container Types Not Found"></ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor="let containerType of filteredContainerTypes | async" [value]="containerType.code">
          {{containerType.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-3">
    <mat-form-field>
      <mat-label>Quantity</mat-label>
      <input matInput type="number" [formControl]="quantity">
    </mat-form-field>
  </div>

  <div class="col-md-12">
    <mat-form-field class="col-xs-12">
      <mat-label>Solas method</mat-label>
      <mat-select [formControl]="solasMethods">
        <mat-option>
          <ngx-mat-select-search [formControl]="solasMethodsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Solas Method Not Found"></ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor="let solasMethod of filteredSolasMethods | async" [value]="solasMethod.code">
          {{solasMethod.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="checkbox-wrap col-md-12">
    <div>Select all that apply:</div>
    <mat-checkbox [formControl]="isSterilized" (click)="sterilizedClick()">Sterilized</mat-checkbox>
    <mat-checkbox [formControl]="isTemptale" (click)="temptaleClick()">Temptale</mat-checkbox>
  </div>

  <div class="col-md-12" *ngIf="isTemptale.value">
    <mat-form-field>
      <mat-label>Temptale description</mat-label>
      <textarea matInput [formControl]="temptaleDescription" rows="3" ></textarea>
    </mat-form-field>
  </div>
  <div class="col-md-4" *ngIf="isTemptale.value">
    <mat-form-field >
      <mat-label>Temptale QTY</mat-label>
      <input matInput type="number" [formControl]="temptaleQTY" />
    </mat-form-field>
  </div>
  <div class="col-md-4">
    <mat-form-field>
      <mat-label>Prelim Consignee</mat-label>
      <input matInput [formControl]="prelimConsignee" />
    </mat-form-field>
  </div>
<div class="col-md-4">
  <mat-form-field>
    <mat-label>Pre-Verification Key</mat-label>
    <input matInput [formControl]="preVerificationKey" />
  </mat-form-field>
</div>
  <div class="col-md-12">
    <mat-form-field>
      <mat-label>Leave a comment</mat-label>
      <textarea matInput [formControl]="comment" rows="3" ></textarea>
    </mat-form-field>
  </div>
  <mat-form-field>
    <mat-label>Step Up</mat-label>
    <textarea matInput [formControl]="stepUp" rows="3" ></textarea>
  </mat-form-field>
  <div class="container-aligned-right col-md-12">
    <!--<button mat-button class="filter-btn"><mat-icon>content_copy</mat-icon>Save & Duplicate Booking</button>-->
    <button mat-button class=" add-booking-btn" (click)="sendToReefers()"><mat-icon>send</mat-icon>Send to GoReefers</button>
  </div>
</div>




