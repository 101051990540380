<div class="dv-content-wrap">

        <div class="dv-header-wrap">
                <div class="detail-header">
                        <h3>Shipment - {{shipmentData.grReference}}</h3>
                </div>
        </div>

        <div class="dv-summary-list">
                <div class="dv-summary-column">
                        <div class="info-item">
                                <span class="info-label">Date created</span>
                                <span>[Data]</span>
                        </div>

                        <div class="info-item">
                                <span class="info-label">Created by</span>
                                <span>[Data]</span>
                        </div>
                </div>

                <div class="dv-summary-column">
                        <div class="info-item">
                                <span class="info-label">Vessel Name & Voyage no.</span>
                                <span>{{shipmentData.vesselVoyage}}</span>
                        </div>

                        <div class="info-item">
                                <span class="info-label">Container no.</span>
                                <span>{{shipmentData.containerNumber}}</span>
                        </div>
                </div>

                <div class="dv-summary-column">
                        <div class="info-item">
                                <span class="info-label">Booking ref.</span>
                                <span>{{shipmentData.bookingRef}}</span>
                        </div>

                        <div class="info-item">
                                <span class="info-label">GR ref.</span>
                                <span>{{shipmentData.grReference}}</span>
                        </div>
                </div>

        </div>

        <mat-button-toggle-group name="favoriteColor" aria-label="Favorite Color" [hideSingleSelectionIndicator]="true"
                class="tab-menu" [(ngModel)]="selectedTab">

                <mat-button-toggle class="tab-menu-item"
                        [value]="detailTabbedViewEnum.trackingTab">Tracking</mat-button-toggle>
                <mat-button-toggle class="tab-menu-item"
                        [value]="detailTabbedViewEnum.documentTab">Documents</mat-button-toggle>
                <mat-button-toggle class="tab-menu-item"
                        [value]="detailTabbedViewEnum.alertTab">Alerts</mat-button-toggle>
                <mat-button-toggle class="tab-menu-item"
                        [value]="detailTabbedViewEnum.detailsTab">Details</mat-button-toggle>

        </mat-button-toggle-group>
        <div class="tab-content">
                <ng-container *ngIf="selectedTab === detailTabbedViewEnum.trackingTab">
                        <div class="status-box">
                                <div class="status-item">
                                  <span class="status-label">Client</span>
                                  <span>{{shipmentData.client}}</span>
                                </div>
                              
                                <div class="status-item">
                                  <span class="status-label">Consignee</span>
                                  <span>{{shipmentData.consignee}}</span>
                                </div>
                                <div class="status-item">
                                  <span class="status-label">Gate In</span>
                                  <span>{{shipmentData.gateIn | date: 'dd MMM yyyy'}}</span>
                                </div>
                                <div class="status-item">
                                  <span class="status-label">Origin Port</span>
                                  <span>{{shipmentData.loadPort}}</span>
                                </div>
                              
                                <div class="status-item">
                                  <span class="status-label">Destination Port</span>
                                  <span>{{shipmentData.dischargePort}}</span>
                                </div>
                              
                                <div class="status-item">
                                  <span class="status-label">Vessel ETA</span>
                                  <span>{{shipmentData.eta | date: 'dd MMM yyyy'}}</span>
                                </div>
                        </div>
                        <app-tracking-map [mode]="transportMode" [startLocation]="startLocation" [endLocation]="endLocation" [voyageCoordinates]="voyageCoordinates"></app-tracking-map>
                         <app-shipment-tracking-detail [alertData]="alertData"></app-shipment-tracking-detail>
                </ng-container>
                <ng-container *ngIf="selectedTab === detailTabbedViewEnum.documentTab">
                        <app-document-management></app-document-management>
                </ng-container>
                <ng-container *ngIf="selectedTab === detailTabbedViewEnum.alertTab">
                        <div class="status-box">
                                <div class="status-item">
                                  <span class="status-label">Original ETA</span>
                                  <span>{{shipmentData.eta | date: 'dd MMM yyyy'}}</span>
                                </div>
                              
                                <div class="status-item">
                                  <span class="status-label">Current ETA</span>
                                  <span>{{shipmentData.eta | date: 'dd MMM yyyy'}}</span>
                                </div>
                                <div class="status-item">
                                  <span class="status-label">Status</span>
                                  <span>{{shipmentData.shipmentStatus}}</span>
                                </div>
                                <div class="status-item">
                                  <span class="status-label">Original ETD</span>
                                  <span>{{shipmentData.etd | date: 'dd MMM yyyy'}}</span>
                                </div>
                              
                                <div class="status-item">
                                  <span class="status-label">Current ETD</span>
                                  <span>{{shipmentData.etd | date: 'dd MMM yyyy' }}</span>
                                </div>
                              
                                <div class="status-item">
                                  <span class="status-label">Delayed by</span>
                                  <span>{{ getDelayInDays(shipmentData.eta, shipmentData.eta) }} days</span>
                                </div>
                              </div>

                        <app-alert-tab [alertData]="alertData"></app-alert-tab>
                </ng-container>
                <ng-container *ngIf="selectedTab === detailTabbedViewEnum.detailsTab">
                        <app-alert-shipment-details></app-alert-shipment-details>
                </ng-container>
        </div>

</div>